/**
 * NAV-FOCUS
 * Focus page with back button on top
 */

import { withTranslation, WithTranslation } from "react-i18next"
import { faArrowLeft, IconDefinition } from "@fortawesome/free-solid-svg-icons"
import Space from "@/components/space"
import { useMediaQuery } from "react-responsive"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import SaveIcon from "@/components/save-icon"
import Button, { ButtonClassName } from "@/components/button"
import ReactTooltip from "react-tooltip"
import { Session } from "@/redux/_session.types"
import { connect } from "react-redux"
import { FadeIn } from "@/utils/animations.utils"
import AdmInvitationsRemainingWidget from "@/widgets/adm-invitations-remaining.widget"
import NavTab from "@/components/nav-tab"
import { NavigateFunction, useNavigate } from "react-router-dom"
import { ReactNode } from "react"
import { NavTabState } from "./nav.dashboard"

export interface NavFocusAction {
  id: string
  icon: IconDefinition
  isLarge?: boolean
  label: string
  className?: ButtonClassName
}

interface StateProps extends WithTranslation {
  _session: Session
}

interface OwnProps {
  children: any
  customActions?: ReactNode
  customNavItems?: ReactNode
  isCreditsDisplayed?: boolean
  isCustomText?: boolean
  isFullWidth?: boolean
  isNotScrollable?: boolean
  isWithMarginTop?: boolean
  isWithoutPadding?: boolean
  onAction?: Function
  onBack?: Function
  status?: string
  tabs?: NavTabState[]
  text?: string
  actions?: NavFocusAction[]
}

type Props = StateProps & OwnProps

function NavFocus(props: Props) {
  const { t } = props
  const navigate: NavigateFunction = useNavigate()

  const isSmall = useMediaQuery({ query: "(max-width : 1200px)" }) //Show small (media query)

  //Clic on back button
  //if route back is defined go to this page
  //Else look for the last page
  function back() {
    if (props.onBack) {
      props.onBack()
    } else {
      navigate(-1) // -> props.history.goBack()
    }
  }

  //Click on action on the top
  function clickAction(actionId) {
    if (props.onAction) {
      props.onAction(actionId)
    }
  }

  //Option if scroll is impossible
  function getPageNavigationClass() {
    let className = "flex1 page-navigation-children flex flex-dcol"

    if (!props.isNotScrollable) {
      className += " page-navigation-scrollable"
    }

    return className
  }

  return (
    <div>
      {/** header */}
      <div className="flex page-navigation-menutop grey-bor">
        <div className="page-navigation-back" onClick={back}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </div>

        {props.text ? (
          <div className="page-navigation-back-text">
            {!props.isCustomText ? t("utils_back_to") + " " : null}
            <span>{props.text}</span>
          </div>
        ) : (
          !props.tabs && (
            <div className="page-navigation-back-text">
              <b>{t("utils_back")}</b>
            </div>
          )
        )}

        {props.tabs?.map((tab: NavTabState) => (
          <NavTab
            key={tab.id}
            id={tab.id}
            icon={tab.icon}
            isActive={tab.id === window.location.pathname}
            onClick={() => clickAction(tab.id)}
            label={t(tab.label)}
          />
        ))}

        <div className="width-10" />

        <div className="flex flex-auto flex-dcol">{props.customNavItems}</div>

        <Space />

        {props.status && <SaveIcon status={props.status} isStatusDisplayed />}

        {props.customActions && props.customActions}

        {props.actions && (
          <div className="flex">
            {props.actions.map((action) => (
              <div
                className="nav-focus-action"
                data-for={action.id}
                data-tip={action.icon ? action.label : null}
                data-place="bottom"
                key={action.id}>
                <Button
                  className={action.className ? action.className : undefined}
                  isWithBorder={action.isLarge}
                  onClick={() => clickAction(action.id)}>
                  {!action.isLarge || isSmall ? (
                    <div>
                      {action.className === "secondary" ? (
                        <FadeIn>
                          <FontAwesomeIcon icon={action.icon} />
                        </FadeIn>
                      ) : (
                        <FontAwesomeIcon icon={action.icon} />
                      )}
                    </div>
                  ) : (
                    <span className="nav-focus-action-large">
                      <FontAwesomeIcon icon={action.icon} />
                      {action.label}
                    </span>
                  )}
                </Button>
                {!action.isLarge && <ReactTooltip id={action.id} />}
              </div>
            ))}
          </div>
        )}

        {props.isCreditsDisplayed && <AdmInvitationsRemainingWidget />}
      </div>

      {props.isWithoutPadding ? (
        <div className="flex page-navigation page-navigation-withheader">{props.children}</div>
      ) : (
        <div className="flex page-navigation page-navigation-withheader">
          <div
            id="page-navigation-scrollable"
            className={getPageNavigationClass()}
            style={{
              height: props.isWithMarginTop ? "calc(100vh - 132px)" : "calc(100vh - 88px)",
              marginTop: props.isWithMarginTop ? "44px" : ""
            }}>
            <div className="flex">
              <Space />
              <div style={{ width: props.isFullWidth ? "100vw" : "1000px" }}>{props.children}</div>
              <Space />
            </div>

            <div className="height-60" />
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  _session: state._session
})

export default connect(mapStateToProps)(withTranslation()(NavFocus))
