/**
 * TASKS-CREATE-CHOICE.WIDGET
 * Create tasks choosing manual or with AI mode
 */

import { useState } from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Topic, TopicState } from "@/redux/topic.types"
import { Session } from "@/redux/_session.types"
import TasksEditModal from "@/modals/tasks-edit.modal"
import TasksGenerateModal from "@/modals/tasks-generate.modal"
import { faFileCirclePlus, faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons"
import CardButton from "@/components/card-button"
import Space from "@/components/space"
import { ActionPlanState } from "@/redux/action-plan.types"
import { SurveyState } from "@/redux/survey.types"

interface StateProps {
  _session: Session
  actionPlan: ActionPlanState
  topic: TopicState
  survey: SurveyState
}

interface OwnProps {
  selectedTopic?: Topic
  onClose?: Function
  isInModal?: boolean
}

type Props = StateProps & OwnProps & WithTranslation

//Add new task modal
const MODAL_ADD: string = "MODAL_ADD"
const MODAL_GENERATE_TASKS: string = "MODAL_GENERATE_TASKS"

function TasksCreateChoiceWidget(props: Props) {
  const { t } = props

  //Display modal
  const [currentModal, setCurrentModal] = useState<string | null>(
    props.isInModal && !props._session.modules.assistant ? MODAL_ADD : null
  )

  function close() {
    setCurrentModal(null)

    if (props.onClose) {
      props.onClose()
    }
  }

  return (
    <>
      {currentModal === MODAL_ADD && <TasksEditModal selectedTopic={props.selectedTopic} onClose={close} />}

      {currentModal === MODAL_GENERATE_TASKS && (
        <TasksGenerateModal selectedTopic={props.selectedTopic} onClose={close} />
      )}

      <div className="flex">
        <Space />

        <CardButton title={t("tasks_list_add")} icon={faFileCirclePlus} onClick={() => setCurrentModal(MODAL_ADD)} />

        {props._session.modules.assistant && props.survey.active.id !== "" && (
          <CardButton
            title={t("tasks_generate")}
            icon={faWandMagicSparkles}
            onClick={() => setCurrentModal(MODAL_GENERATE_TASKS)}
          />
        )}

        <Space />
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  _session: state._session,
  actionPlan: state.actionPlan,
  topic: state.topic,
  survey: state.survey
})

export default connect(mapStateToProps)(withTranslation()(TasksCreateChoiceWidget))
