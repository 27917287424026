/**
 * TASKS-ADD.MODAL
 * Create new action plan (for myself or a manager)
 */
import Modal from "@/components/modal"
import { WithTranslation, withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Session } from "@/redux/_session.types"
import { User, UserState } from "@/redux/user.types"
import { useState } from "react"
import { store } from "@/index"
import { NavigateFunction, useNavigate } from "react-router-dom"
import { actionPlanAdd } from "@/redux/action-plan.actions"
import CardContainer from "@/components/card-container"
import CardButton from "@/components/card-button"
import { faCrown, faPaperPlane } from "@fortawesome/free-solid-svg-icons"
import SearchUser from "@/components/search-user"
import { ActionPlanStatus } from "@/redux/action-plan.types"

interface StateProps extends WithTranslation {
  _session: Session
  user: UserState
}

interface OwnProps {
  onClose: Function
}

type Props = StateProps & OwnProps

const MODAL_SEARCH = "MODAL_SEARCH"

function TasksAddModal(props: Props) {
  const { t } = props
  const navigate: NavigateFunction = useNavigate()

  const [user, setUser] = useState(initUser())
  const [currentModal, setCurrentModal] = useState<null | string>(null)

  function create() {
    let status: ActionPlanStatus = "draft"
    let isShared = false
    let name = t("tasks_name_empty")

    //Case when an admin prepare an action plan for his manager
    if (user.id !== props._session.userId && props._session.userRole === "ADMIN") {
      status = "predraft"
      name = t("tasks_new_admin", { name: user.username })
      isShared = true
    }

    store.dispatch(actionPlanAdd(user, null, name, status, isShared))
    navigate("/tasks/edit?isNew=true")
  }

  function initUser() {
    return new User({
      id: props._session.userId,
      email: props._session.email,
      firstname: props._session.userFirstname,
      lastname: props._session.userLastname,
      role: props._session.userRole
    })
  }

  return (
    <Modal isCloseButtonVisible title={t("tasks_add")} onClose={() => props.onClose()} onNext={create}>
      {currentModal === MODAL_SEARCH && (
        <div
          className="abs"
          style={{
            marginLeft: 200,
            marginTop: -98,
            width: 240
          }}>
          <SearchUser
            activeUsers={[user]}
            onClose={() => setCurrentModal(null)}
            onSelect={(user) => setUser(user)}
            isAdmin
          />
        </div>
      )}

      <CardContainer>
        <CardButton
          icon={faCrown}
          isSmall
          isActive={user.id === props._session.userId}
          title={t("tasks_add_self")}
          onClick={() => setUser(initUser)}></CardButton>
        <CardButton
          icon={faPaperPlane}
          isSmall
          isActive={user.id !== props._session.userId}
          title={props._session.userRole === "ADMIN" ? t("tasks_add_manager") : t("tasks_add_shared")}
          onClick={() => setCurrentModal(MODAL_SEARCH)}></CardButton>
      </CardContainer>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  _session: state._session,
  user: state.user
})

export default connect(mapStateToProps)(withTranslation()(TasksAddModal))
