//_INDEX.REDUCERS.JS
//Liste de tout les objets manipulés dans l'application

/**
 * COMMENT STRUCTURER LES ACTIONS
 * 

# Convention de nommage pour une action
<reducer><action><attribut?>

# REDUCER => objet à modifier
ex : filter, account

# ACTION => quel opération réaliser sur l'objet
ex filterActivate, accountGet

=== liste des actions possibles ===
activate => sélectionner une ligne pour la rendre active
add => créer une ligne côté front
cancel => annuler une opération
copy => ajouter un user dans la liste à partir d'une liste existante
duplicate => créer un enregistrement en BDD à partir d'un enregistrement actuel
edit => modifier l’enregistrement actif côté front
destroy => supprimer une ligne dans la BDD
fetch => récupérer la liste des utilisateurs dans la BDD
get => mettre à jour la liste côté front
init => initialiser le store
insert => créer une ligne dans la BDD
remove => supprimer une ligne côté front
replace => remplace an item by another one wich the same id
save => sauvegarder les modifications côté front
status => définir le statut
update => mettre à jour la ligne dans la BDD
upload => uploader une image sur le serveur

# ATTRIBUT => paramètre optionnel sur l'action
ex : filterEditName, accountGet_AsSupervisor


*/

import { combineReducers } from "redux"
import _session from "../redux/_session.reducers"
import account from "../redux/account.reducers"
import actionPlan from "../redux/action-plan.reducers"
import attribute from "../redux/attribute.reducers"
import axis from "../redux/axis.reducers"
import bookmark from "../redux/bookmark.reducers"
import email from "../redux/email.reducers"
import filter from "../redux/filter.reducers"
import invitation from "../redux/invitation.reducers"
import learning from "../redux/learning.reducers"
import lesson from "../redux/lesson.reducers"
import message from "../redux/message.reducers"
import page from "../redux/page.reducers"
import project from "../redux/project.reducers"
import proposition from "../redux/proposition.reducers"
import question from "../redux/question.reducers"
import regrouping from "../redux/regrouping.reducers"
import reminder from "../redux/reminder.reducers"
import report from "../redux/report.reducers"
import sending from "../redux/sending.reducers"
import subscription from "../redux/subscription.reducers"
// import supervisor from "../redux/supervisor.reducers"
import survey from "../redux/survey.reducers"
import template from "../redux/template.reducers"
import topic from "../redux/topic.reducers"
import user from "../redux/user.reducers"
import supervisorSlice from "./slices/supervisor/supervisor.slice"
import { backendApi } from "./apis/backend/backend.api"

export default combineReducers({
  _session,
  account,
  actionPlan,
  attribute,
  axis,
  bookmark,
  email,
  filter,
  invitation,
  learning,
  lesson,
  message,
  page,
  project,
  proposition,
  question,
  regrouping,
  reminder,
  report,
  sending,
  subscription,
  // supervisor,
  survey,
  template,
  topic,
  user,
  supervisor: supervisorSlice,
  [backendApi.reducerPath]: backendApi.reducer
})
