/**
 * TASKS-ACTION-PLANS.WIDGET
 * List of actions plans
 */

import { faCheck, faPencilAlt, faTimes, faUserCircle } from "@fortawesome/free-solid-svg-icons"
import Card from "@/components/card"
import ListButton from "@/components/list-button"
import ListCell from "@/components/list-cell"
import ListFields from "@/components/list-fields"
import ListIcon from "@/components/list-icon"
import ListItem from "@/components/list-item"
import Space from "@/components/space"
import { store } from "@/index"
import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Session } from "@/redux/_session.types"
import { actionPlanActivate, actionPlanFetch, actionPlanFetchSelf } from "@/redux/action-plan.actions"
import { ActionPlan } from "@/redux/action-plan.types"
import { userActivate } from "@/redux/user.actions"
import AdmUserPopulationsWidget from "./adm-user-populations.widget"
import Chip from "@/components/chip"
import ProgressBar from "@/components/progress-bar"
import { NavigateFunction, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { STATUS_LOADED, STATUS_LOADING } from "@/redux/_status.types"
import { topicGet } from "@/redux/topic.actions"

interface StateProps extends WithTranslation {
  _session: Session
}

interface OwnProps {
  title?: string
  isSelf?: boolean
  isPredraft?: boolean
}

type Props = StateProps & OwnProps

function TasksActionPlansWidget(props: Props) {
  const { t } = props

  const navigate: NavigateFunction = useNavigate()
  const [actionPlans, setActionsPlans] = useState<ActionPlan[]>([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    loadData()

    async function loadData() {
      let response: any = {}
      if (props.isSelf) {
        response = await store.dispatch(actionPlanFetchSelf())
      } else if (props.isPredraft) {
        response = await store.dispatch(actionPlanFetch("predraft"))
      } else {
        response = await store.dispatch(actionPlanFetch("active"))
      }

      //Update store
      store.dispatch(topicGet([])) //Init topic (for init the edit page)
      setActionsPlans(response.error ? [] : response.map((x) => new ActionPlan(x)))
      setIsLoading(false)
    }
  }, [])

  //Edit draft action plan
  function editActionPlan(actionPlan: ActionPlan) {
    store.dispatch(userActivate(actionPlan.User))
    store.dispatch(actionPlanActivate(actionPlan))
    navigate("/tasks/edit")
  }

  return (
    <div>
      {props.title && <h3>{props.title}</h3>}

      <Card isWithoutPadding status={isLoading ? STATUS_LOADING : STATUS_LOADED}>
        <ListFields>
          {props.isSelf ? (
            <div className="flex1 flex">
              <ListCell width={300} text={t("tasks_priority")} />
              <Space />
              <ListCell width={100} text={t("tasks_is_shared_short")} />
            </div>
          ) : (
            <div className="flex1 flex">
              <ListCell />
              <ListCell
                width={170}
                text={props._session.userRole === "ADMIN" ? t("tasks_teams_manager") : t("tasks_contact")}
              />
              <ListCell width={155} text={t("tasks_teams_populations")} />
            </div>
          )}
          <ListCell width={400} text={t("tasks_progress")} />
        </ListFields>

        {actionPlans.length === 0 && <ListFields>{t("action_plan_no_active")}</ListFields>}

        {actionPlans.map((actionPlan: ActionPlan) => (
          <ListItem key={actionPlan.id} onClick={() => editActionPlan(actionPlan)} isEditable>
            {props.isSelf ? (
              <div className="flex1 flex">
                <ListCell width={300}>{actionPlan.priority}</ListCell>
                <Space />
                <ListCell width={100}>
                  <Chip
                    icon={actionPlan.isShared ? faCheck : faTimes}
                    color={actionPlan.isShared ? "#20CA7E" : "#eb5a46"}
                    isOutlineColored></Chip>
                </ListCell>
              </div>
            ) : (
              <div className="flex1 flex">
                <ListIcon fallbackIcon={faUserCircle} image={actionPlan.User.imageUrl} />
                <ListCell width={170} text={actionPlan.User.username} />
                <AdmUserPopulationsWidget currentUser={actionPlan.User} />
              </div>
            )}

            <ListCell width={400}>
              <div className="flex">
                {actionPlan.status === "active" ? (
                  <div style={{ width: 120 }}>
                    <div style={{ margin: "-6px 0px" }}>
                      <ProgressBar items={[{ value: actionPlan.tasksDoneCount }]} max={actionPlan.tasks.length} />
                    </div>
                  </div>
                ) : (
                  <Chip>{actionPlan.statusLabel}</Chip>
                )}
              </div>

              <Space />

              <ListButton icon={faPencilAlt} text={t("utils_edit")} onClick={() => editActionPlan(actionPlan)} />
            </ListCell>
          </ListItem>
        ))}
      </Card>
    </div>
  )
}

const mapStateToProps = (state) => ({
  _session: state._session
})

export default connect(mapStateToProps)(withTranslation()(TasksActionPlansWidget))
