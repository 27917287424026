/**
 * USER
 * Utilisateur d'un compte IDTree
 */

import env from "@/env"
import { formatDate } from "@/utils/format-date.utils"
import { Account, AccountScoreResult } from "./account.types"
import { Population } from "./population.types"
import { Question } from "./question.types"
import { CONNEXION_MODE, Session } from "./_session.types"
import { faCrown, faEye } from "@fortawesome/free-solid-svg-icons"
import { t } from "@/translate/t"

export type USER_ROLE = "ADMIN" | "OBSERVER" | "MANAGER"

//Init all roles
export const USER_ROLES = [
  { id: "OBSERVER", icon: faEye },
  //{ id: "MANAGER", icon: faUserTie },
  { id: "ADMIN", icon: faCrown }
] as const

export class UserOptions {
  ceilsRepartition: number[] = [6, 8] //Ceils of satisfaction for the repartition widget
  dashboardApplyUserSettings: boolean = false //Use account options by default
  dashboardDisplayMode: AccountScoreResult = "note" //show note /10 or % of satisfaction
  dashboardDisplayHelp: boolean = true
  dashboardNightmareColor1: string = "#eb5a46" //Same as AccountOptions
  dashboardNightmareColor2: string = "#CCCCCC" //Same as AccountOptions
  dashboardNightmareColor3: string = "#1CB06E" //Same as AccountOptions
  dashboardNightmareModeDisplay: boolean = false //Same as AccountOptions
  dashboardNightmareModeMax: number = 80 //Same as AccountOptions
  dashboardNightmareModeMin: number = 50 //Same as AccountOptions
  dashboardNightmareModeHeatmap: boolean = false //Same as AccountOptions
  activateQuestions: boolean = false //Set whether or not is draft for each question created in editor
  editorDisplayTopic: boolean = true
  editorDisplayQuestion: boolean = true
  editorDisplayPrimary: boolean = true
  editorDisplayType: boolean = false
  editorDisplayAxis: boolean = false
  editorDisplayFullScreen: boolean = false
  editorDisplayWithScore: boolean = false
  editorDisplayReverse: boolean = false
  editorDisplaySpecial: boolean = false
  editorDisplayTrigger: boolean = false
  filterOrder: string[] = [] //Order to display filters in dashboard
  hideNoAnswers: boolean = false //Don't show count of no answer by questions
  hideStartedForParticipation: boolean = false //Don't show the "started in the dashboard"
  hideWizardEditor: boolean = false //Don't show the owl and its recommandations
  googlePhotoURL: string | null = null
  image: boolean = false

  constructor(userOptions: Partial<UserOptions> = {}) {
    Object.assign(this, userOptions)
  }
}

export class User {
  address: string | null = null
  attributes: any = {}
  birthDate: Date | null = null
  connexionMode: CONNEXION_MODE | null = null
  companyWelcomeDate: Date | null = null
  createdAt: Date = new Date()
  passwordProvided: boolean = false
  email: string | null = ""
  emailStatus: string = "unverified"
  excludedFilters: string[] = []
  firstname: string = ""
  gender: string | null = null
  id: string = ""
  invitationDate: Date = new Date()
  invitationStatus: string = "invitation_not_invited"
  importStatus: string | null = null
  language: string = "fr"
  lastname: string = ""
  level: number = 0 //Level of supervisor
  open: boolean = false
  options: UserOptions = new UserOptions()
  phone: string | null = null
  populationsObserver: Population[] = []
  Questions: Question[] = []
  role: USER_ROLE | null = null
  invitedAt: Date | null = null
  connectedAt: Date | null = null
  selected: boolean = false
  AccountId: string = ""
  Account: Account = new Account()
  username: string = ""

  constructor(user: Partial<User> = {}) {
    if (!user) user = new User()
    user.options = new UserOptions(user.options)
    user.attributes = user.attributes ? user.attributes : {}
    user.Questions = user.Questions ? user.Questions.map((x) => new Question(x)) : []
    user.excludedFilters = user.excludedFilters ? user.excludedFilters : []
    user.populationsObserver = user.populationsObserver ? user.populationsObserver.map((x) => new Population(x)) : []
    user.Account = new Account(user.Account)

    if (user.lastname || user.firstname) {
      user.username = user.firstname + " " + user.lastname
    } else if (user.email) {
      user.username = user.email.split("@")[0].replace(".", " ")
    } else {
      user.username = ""
    }

    Object.assign(this, user)
  }

  get birthDateLabel(): string {
    return this.birthDate ? new Date(this.birthDate).toLocaleDateString() : ""
  }

  get companyWelcomeDateLabel(): string {
    return this.companyWelcomeDate ? new Date(this.companyWelcomeDate).toLocaleDateString() : ""
  }

  get createdAtLabel(): string {
    return formatDate(this.createdAt, true, false)
  }

  get genderLabel(): string {
    if (this.gender) {
      const gender = USER_LIST_GENDER.find((x) => x.id === this.gender)
      return gender ? t(gender.name) : ""
    } else {
      return ""
    }
  }

  //Get image url
  get imageUrl(): string | null {
    if (this.options.image) {
      return env.REACT_APP_URL_SPACE.concat("/Users/", this.id, ".png")
    } else if (this.options.googlePhotoURL) {
      return this.options.googlePhotoURL
    } else {
      return null
    }
  }

  //IS email confirmed
  get isEmailConfirmed(): boolean {
    return this.emailStatus === "confirmed"
  }

  //Get link for invitation email
  getInvitationLink(session: Session): string {
    if (session.modules.sso) {
      return env.REACT_APP_URL_OBSERVER + "/login/sso/auth/" + session.accountOptions.ssoOrganization
    } else {
      return env.REACT_APP_URL_OBSERVER + "/login/mail?id=" + this.id
    }
  }
}

export class UserRps {
  Questions: Question[] = []
  autonomy: number = 0
  decisionLatitude: number = 0
  decisionLatitudeActive: boolean = false
  fkUser: string = ""
  managerSupport: number = 0
  managerSupportActive: boolean = false
  psychologicalJobDemands: number = 0
  psychologicalJobDemandsActive: boolean = false
  skillUse: number = 0
  socialSupport: number = 0
  socialSupportActive: boolean = false
  teamSupport: number = 0
  teamSupportActive: boolean = false
  workGratitude: number = 0
  workGratitudeActive: boolean = false

  constructor(userRps: Partial<UserRps> = {}) {
    userRps.Questions = userRps.Questions ? userRps.Questions.map((x) => new Question(x)) : []
    Object.assign(this, userRps)
  }
}

export interface UserState {
  active: User
  count: number
  countSearch: number
  currentSearch: string
  currentOffset: 0
  list: User[]
  listExcluded: User[]
  status: string
}

//List of value gender
export const USER_LIST_GENDER = [
  { id: null, name: "utils_undefined" },
  { id: "f", name: "user_gender_f" },
  { id: "m", name: "user_gender_m" }
]

//List of fields for the user (mapping)
export const USER_FIELDS = [
  { id: "email", name: "user_email" },
  { id: "firstname", name: "user_firstname" },
  { id: "lastname", name: "user_lastname" },
  { id: "gender", name: "user_gender" },
  { id: "birthDate", name: "user_birth_date" },
  { id: "companyWelcomeDate", name: "user_company_welcome_date" },
  { id: "language", name: "user_language" },
  { id: "phone", name: "user_phone" },
  { id: "address", name: "user_address" }
]

export const USER_ADD = "USER_ADD"
export const USER_COPY = "USER_COPY"
export const USER_EDIT = "USER_EDIT"
export const USER_EDIT_CURRENT_SEARCH = "USER_EDIT_CURRENT_SEARCH"
export const USER_EDIT_CURRENT_OFFSET = "USER_EDIT_CURRENT_OFFSET"
export const USER_EDIT_ATTRIBUTE = "USER_EDIT_ATTRIBUTE"
export const USER_ACTIVATE = "USER_ACTIVATE"
export const USER_GET = "USER_GET"
export const USER_GET_EXCLUDED = "USER_GET_EXCLUDED"
export const USER_GET_COUNT = "USER_GET_COUNT"
export const USER_GET_COUNT_SEARCH = "USER_GET_COUNT_SEARCH"
export const USER_INIT = "USER_INIT"
export const USER_REMOVE = "USER_REMOVE"
export const USER_REMOVE_ATTRIBUTE_MANAGER = "USER_REMOVE_ATTRIBUTE_MANAGER"
export const USER_STATUS = "USER_STATUS"
