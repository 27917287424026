/**
 * TASKS-ACTIONS.WIDGET
 * List of actions possible for the tasks widget
 */
import { store } from "@/index"
import { useState } from "react"
import { connect } from "react-redux"
import { ActionPlanState } from "@/redux/action-plan.types"
import { Session } from "@/redux/_session.types"
import { WithTranslation, withTranslation } from "react-i18next"
import Space from "@/components/space"
import { actionPlanSend } from "@/redux/action-plan.actions"
import { toast } from "react-toastify"
import { UserState } from "@/redux/user.types"
import Button from "@/components/button"
import {
  faArchive,
  faCog,
  faCopy,
  faDownload,
  faEnvelope,
  faPaperPlane,
  faPlay,
  faPlus,
  faStop,
  faTrash
} from "@fortawesome/free-solid-svg-icons"
import { exportActionPlan } from "@/utils/export-action-plan.utils"
import ModalConfirm from "@/components/modal-confirm"
import TasksArchiveModal from "@/modals/tasks-archive.modal"
import TasksCloseModal from "@/modals/tasks-close.modal"
import TasksDeleteModal from "@/modals/tasks-delete.modal"
import TasksOptionsModal from "@/modals/tasks-options.modal"
import TasksLaunchModal from "@/modals/tasks-launch.modal"
import TasksRelaunchModal from "@/modals/tasks-relaunch.modal"
import Modal from "@/components/modal"
import TasksCreateChoiceWidget from "./tasks-create-choice.widget"
import ListButton from "@/components/list-button"
import env from "@/env"

interface StateProps extends WithTranslation {
  _session: Session
  actionPlan: ActionPlanState
  user: UserState
}

const MODAL_ADD: string = "MODAL_ADD"
const MODAL_ARCHIVE: string = "MODAL_ARCHIVE"
const MODAL_CLOSE: string = "MODAL_CLOSE"
const MODAL_DELETE: string = "MODAL_DELETE"
const MODAL_EDIT_OPTIONS: string = "MODAL_EDIT_OPTIONS"
const MODAL_LAUNCH: string = "MODAL_LAUNCH"
const MODAL_RELAUNCH: string = "MODAL_RELAUNCH"
const MODAL_SEND: string = "MODAL_SEND"

function TasksActionsWidget(props: StateProps) {
  const { t } = props

  //Display modal
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  function copyLink() {
    const link = env.REACT_APP_URL_OBSERVER + "/login/mail?id=" + props.user.active.id + "&redirection=tasks"
    navigator.clipboard.writeText(link)
    return toast(t("utils_copy_link_done"))
  }

  function launch() {
    if (props.actionPlan.active.tasks.length > 0) {
      setCurrentModal(MODAL_LAUNCH)
    } else {
      toast(t("tasks_list_empty"), { type: "error" })
    }
  }

  //Send invitation email
  function send() {
    const response: any = store.dispatch(actionPlanSend(props.actionPlan.active.id, props._session.userName))
    if (!response.error) {
      setCurrentModal(null)
      toast(t("utils_email_send", { email: props.user.active.email }))
    }
  }

  return (
    <div className="flex flex1">
      {currentModal === MODAL_ADD && (
        <Modal title={t("tasks_list_add")} onClose={() => setCurrentModal(null)}>
          <TasksCreateChoiceWidget onClose={() => setCurrentModal(null)} isInModal />
        </Modal>
      )}

      {currentModal === MODAL_SEND && (
        <ModalConfirm
          onNo={() => setCurrentModal(null)}
          onYes={send}
          textBold={t("tasks_launch_text_1", {
            name: props.user.active.username,
            email: props.user.active.email
          })}
        />
      )}

      {currentModal === MODAL_ARCHIVE && <TasksArchiveModal onClose={() => setCurrentModal(null)} />}
      {currentModal === MODAL_CLOSE && <TasksCloseModal onClose={() => setCurrentModal(null)} />}
      {currentModal === MODAL_DELETE && <TasksDeleteModal onClose={() => setCurrentModal(null)} />}
      {currentModal === MODAL_EDIT_OPTIONS && <TasksOptionsModal onClose={() => setCurrentModal(null)} />}
      {currentModal === MODAL_LAUNCH && <TasksLaunchModal onClose={() => setCurrentModal(null)} />}
      {currentModal === MODAL_RELAUNCH && <TasksRelaunchModal onClose={() => setCurrentModal(null)} />}

      <Space />

      <div className="flex flex-auto">
        <ListButton
          text={t("utils_advanced_options")}
          tooltipPosition="bottom"
          icon={faCog}
          onClick={() => setCurrentModal(MODAL_EDIT_OPTIONS)}
        />

        <ListButton
          text={t("tasks_delete")}
          tooltipPosition="bottom"
          icon={faTrash}
          onClick={() => setCurrentModal(MODAL_DELETE)}
        />

        <ListButton
          text={t("tasks_list_add")}
          tooltipPosition="bottom"
          icon={faPlus}
          onClick={() => setCurrentModal(MODAL_ADD)}
        />

        {props.actionPlan.active.status === "active" ? (
          <div className="flex">
            {props.actionPlan.active.UserId !== props._session.userId && (
              <ListButton
                text={t("utils_copy_link")}
                tooltipPosition="bottom"
                icon={faCopy}
                onClick={() => copyLink()}
              />
            )}

            {props.actionPlan.active.UserId !== props._session.userId && (
              <ListButton
                text={t("tasks_send")}
                tooltipPosition="bottom"
                icon={faEnvelope}
                onClick={() => setCurrentModal(MODAL_SEND)}
              />
            )}

            <ListButton
              text={t("tasks_close")}
              tooltipPosition="bottom"
              icon={faStop}
              onClick={() => setCurrentModal(MODAL_CLOSE)}
            />
          </div>
        ) : (
          <ListButton
            isPrimary
            text={t("tasks_launch")}
            tooltipPosition="bottom"
            icon={faPaperPlane}
            onClick={() => launch()}
          />
        )}

        {props.actionPlan.active.status === "closed" && (
          <div className="flex">
            <ListButton
              text={t("tasks_relaunch")}
              tooltipPosition="bottom"
              icon={faPlay}
              onClick={() => setCurrentModal(MODAL_RELAUNCH)}
            />
            <ListButton
              text={t("tasks_archive")}
              tooltipPosition="bottom"
              icon={faArchive}
              onClick={() => setCurrentModal(MODAL_ARCHIVE)}
            />
          </div>
        )}
      </div>

      <div className="width-10" />

      <Button
        icon={faDownload}
        onClick={() => exportActionPlan(props.actionPlan.active)}
        isWithBorder
        className="secondary">
        {t("utils_export")}
      </Button>
    </div>
  )
}

const mapStateToProps = (state) => ({
  _session: state._session,
  actionPlan: state.actionPlan,
  user: state.user
})

export default connect(mapStateToProps)(withTranslation()(TasksActionsWidget))
