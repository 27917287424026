/**
 * TASKS-EDIT.MODAL
 * Add or edit a task for an action plan
 */
import Modal from "@/components/modal"
import { WithTranslation, withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Session } from "@/redux/_session.types"
import {
  ActionPlanState,
  ActionPlanTask,
  ActionPlanTaskMessage,
  ActionPlanTaskStatus,
  TASK_STATUS_LIST
} from "@/redux/action-plan.types"
import { UserState } from "@/redux/user.types"
import { useEffect, useState } from "react"
import { Topic, TopicState } from "@/redux/topic.types"
import { store } from "@/index"
import {
  actionPlanAddTask,
  actionPlanAddTaskMessage,
  actionPlanEditTask,
  actionPlanFetchTasks,
  actionPlanRemoveTask,
  actionPlanRemoveTaskMessage,
  actionPlanSendMessage
} from "@/redux/action-plan.actions"
import ModalConfirm from "@/components/modal-confirm"
import PageLoader from "@/components/page-loader"
import { STATUS_LOADING } from "@/redux/_status.types"
import ListItem from "@/components/list-item"
import { FadeIn } from "@/utils/animations.utils"
import Button from "@/components/button"
import Space from "@/components/space"
import TasksTopicLabelWidget from "@/widgets/tasks-topic-label.widget"
import Link from "@/components/link"
import { toast } from "react-toastify"
import { formatDate } from "@/utils/format-date.utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { sortBy } from "lodash"
import TasksTopicModal from "./tasks-topic.modal"
import Dropdown from "@/components/dropdown"
import TextInput from "@/components/text-input"
import TopicTaskWidget from "@/widgets/topic-task.widget"

interface StateProps extends WithTranslation {
  _session: Session
  actionPlan: ActionPlanState
  topic: TopicState
  user: UserState
}

interface OwnProps {
  currentTask?: ActionPlanTask //Task to display
  isModeActive?: boolean //Edit mode or follow-up mode (with messages)
  onClose: Function //Trigger on close
  selectedTopic?: Topic
}

type Props = StateProps & OwnProps

interface TopicTask {
  text: string
  UserResponsibles: string[]
}

const MODAL_DELETE = "MODAL_DELETE"
const MODAL_MESSAGE = "MODAL_MESSAGE"
const MODAL_MESSAGE_CONFIRM = "MODAL_MESSAGE_CONFIRM"
const MODAL_TOPIC = "MODAL_TOPIC"

function TasksEditModal(props: Props) {
  const { t } = props

  //Get text
  const [currentTask, setCurrentTask] = useState(props.currentTask ? props.currentTask : new ActionPlanTask())

  //Current selected message (for delete)
  const [currentMessage, setCurrentMessage] = useState<null | ActionPlanTaskMessage>(null)

  //Display modal
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  //Is topic tasks loading
  const [isTopicTasksLoading, setIsTopicTasksLoading] = useState(
    props.currentTask && !props.isModeActive ? false : true
  )

  //Write message to HR
  const [message, setMessage] = useState("")

  //Recipient of the email for messages
  const [recipient] = useState(props.user.active)

  //List of propositions of tasks
  const [topicTasks, setTopicTasks] = useState<TopicTask[]>([])

  //Selected topic
  const [selectedTopic, setSelectedTopic] = useState<Topic | undefined>(props.selectedTopic)

  //On load get actions already given
  useEffect(() => {
    if (!props.currentTask && !props.isModeActive) {
      loadTopicTasks()
    }

    async function loadTopicTasks() {
      setIsTopicTasksLoading(true)

      if (props.topic.active.aid && props._session.userRole === "ADMIN") {
        const response: any = await store.dispatch(actionPlanFetchTasks(props.topic.active.aid))
        setTopicTasks(response.error ? [] : response)
      }

      setIsTopicTasksLoading(false)
    }
  }, [props._session.userRole, props.isModeActive, props.topic.active.aid, props.currentTask])

  //Delete a message
  function deleteMessage() {
    if (props.currentTask && currentMessage) {
      store.dispatch(actionPlanRemoveTaskMessage(props.currentTask.id, currentMessage.id))
    }

    setCurrentMessage(null)
  }

  //Delete the task
  function deleteTask() {
    if (props.currentTask) {
      store.dispatch(actionPlanRemoveTask(props.currentTask.id))
    }

    props.onClose()
  }

  //Update task
  function editCurrentTask(key: string, value: string) {
    setCurrentTask(Object.assign({}, currentTask, { [key]: value }))
  }

  //Change status
  function editStatus(status: string) {
    editCurrentTask("status", status)
    store.dispatch(actionPlanEditTask(Object.assign({}, currentTask, { status })))
  }

  //Custom title according status of the action plan
  function getTitle() {
    if (props.isModeActive) {
      return undefined
    } else if (props.currentTask) {
      return t("tasks_list_edit") + " "
    } else {
      return t("tasks_list_add")
    }
  }

  //Get list of topics
  //> first item active topic
  //> then sorted by note
  function getTopics() {
    const topicsWithTask = props.actionPlan.active.tasks.map((x) => x.topicAid)
    return [props.topic.active].concat(
      sortBy(
        props.topic.list
          .concat([new Topic({ withScore: true })])
          .filter((x) => x.withScore && topicsWithTask.indexOf(x.aid) === -1 && x.aid !== props.topic.active.aid),
        "note"
      )
    )
  }

  //Click on confirm
  async function next() {
    if (props.currentTask) {
      const updatedTask = new ActionPlanTask(currentTask)
      updatedTask.topicAid = props.topic.active.aid
      store.dispatch(actionPlanEditTask(updatedTask))
    } else {
      store.dispatch(
        actionPlanAddTask(props.topic.active.aid, currentTask.title, currentTask.text, props._session.userName)
      )
    }

    props.onClose()
  }

  //Select existing tasks
  function selectTopicTask(topicTask: TopicTask) {
    editCurrentTask("text", topicTask.text)
    setTopicTasks([])
  }

  //Send message to other contact
  //Save the message
  async function sendMessage() {
    if (props.currentTask) {
      store.dispatch(
        actionPlanAddTaskMessage(props.currentTask.id, message, props._session.userName, props._session.userId)
      )

      //Detect recipient
      //If admin it is the user responsible
      //If user it is the HR contact
      const isSendToAdmin = props._session.userRole !== "ADMIN" ? true : false

      if (recipient?.email) {
        const response: any = await store.dispatch(
          actionPlanSendMessage(
            props.actionPlan.active.id,
            props.currentTask.text,
            message,
            props._session.userName,
            isSendToAdmin
          )
        )
        if (!response.error) {
          toast(t("utils_email_send", { email: recipient.email }))
        }
      }
    }

    setCurrentModal(null)
  }

  return (
    <Modal
      isCloseButtonVisible
      disableClickOutside
      disableKeyEvents
      title={getTitle()}
      isLarge
      onClose={() => props.onClose()}
      onDelete={props.currentTask && !props.isModeActive ? () => setCurrentModal(MODAL_DELETE) : undefined}
      onNext={currentTask.text.length > 0 && !props.isModeActive ? next : undefined}>
      {currentModal === MODAL_DELETE && (
        <ModalConfirm
          onNo={() => setCurrentModal(null)}
          onYes={deleteTask}
          text={t("tasks_list_delete_ask")}
          textBold={t("utils_next_ask")}
        />
      )}

      {currentModal === MODAL_TOPIC && <TasksTopicModal onClose={() => setCurrentModal(null)} />}

      {currentMessage && (
        <ModalConfirm
          onNo={() => setCurrentModal(null)}
          onYes={deleteMessage}
          text={t("message_ask_delete", {
            message: currentMessage.text
          })}
          textBold={t("utils_next_ask")}
        />
      )}

      {currentModal === MODAL_MESSAGE && (
        <Modal
          title={t("tasks_add_message")}
          onClose={() => setCurrentModal(null)}
          disableKeyEvents
          disableClickOutside
          isCloseButtonVisible
          onNext={() => {
            if (recipient?.email) {
              setCurrentModal(MODAL_MESSAGE_CONFIRM)
            } else {
              sendMessage()
            }
          }}>
          <p>{props.currentTask?.text}</p>
          <textarea
            style={{
              resize: "none",
              height: "140px",
              width: "calc(100% - 20px)",
              border: "none",
              padding: "6px 10px",
              backgroundColor: "#eaeaea",
              borderRadius: "8px",
              margin: "12px 0px"
            }}
            autoFocus
            onChange={(e) => setMessage(e.target.value)}
            value={message}></textarea>

          {recipient?.email && (
            <p className="grey-t">
              <b>
                {t(props._session.userRole === "ADMIN" ? "tasks_message_help_admin" : "tasks_message_help_user", {
                  name: recipient.username
                })}
              </b>
            </p>
          )}
        </Modal>
      )}

      {currentModal === MODAL_MESSAGE_CONFIRM && (
        <ModalConfirm
          onNo={() => setCurrentModal(null)}
          status={props.actionPlan.status}
          onYes={sendMessage}
          textBold={t("utils_next_ask")}
          text={t("tasks_launch_message", {
            name: recipient?.username,
            email: recipient?.email
          })}
        />
      )}

      {props.isModeActive ? (
        props.currentTask ? (
          <div>
            <div className="flex">
              <div className="_hover" onClick={() => setCurrentModal(MODAL_TOPIC)}>
                <TasksTopicLabelWidget currentTask={props.currentTask} />
              </div>

              <Space />

              {props.actionPlan.active.status === "active" && (
                <Dropdown
                  value={t("tasks_list_status_" + currentTask.status)}
                  displayField="name"
                  active={currentTask.status}
                  list={TASK_STATUS_LIST.map((x: ActionPlanTaskStatus) => {
                    return {
                      id: x,
                      name: t("tasks_list_status_" + x)
                    }
                  })}
                  onSelect={(status) => editStatus(status.id)}
                />
              )}

              <div className="width-40" />
            </div>

            <p style={{ fontSize: 18 }}>
              <b>{props.currentTask.title}</b>
            </p>

            <p style={{ fontSize: 18 }}>{props.currentTask.text}</p>

            {props.currentTask.messages.map((message: ActionPlanTaskMessage) => (
              <div
                key={message.id}
                className="tasks-message grey-t"
                style={
                  message.userId === props._session.userId
                    ? {
                        backgroundColor: props._session.accountColors.active,
                        color: "white",
                        marginLeft: 40
                      }
                    : {
                        backgroundColor: "#e6e6e6",
                        marginRight: 40
                      }
                }>
                <div className="flex">
                  {message.text}

                  <Space />

                  {message.userId === props._session.userId && props.actionPlan.active.status === "active" && (
                    <FontAwesomeIcon icon={faTimes} className="_hover" onClick={() => setCurrentMessage(message)} />
                  )}
                </div>

                <div className="flex tasks-message-footer">
                  <Space />
                  <b>{message.user}</b>
                </div>

                <div className="flex tasks-message-footer">
                  <Space />
                  {formatDate(message.date, true, false)}
                </div>
              </div>
            ))}

            {props.actionPlan.active.status === "active" && (
              <Link
                onClick={() => {
                  setMessage("")
                  setCurrentModal(MODAL_MESSAGE)
                }}
                isWithoutMargin>
                {t("tasks_add_message")}
              </Link>
            )}

            <div className="height-100" />
          </div>
        ) : null
      ) : (
        <div className="flex">
          <div style={{ width: 260 }}>
            <p className="grey-t">{t("topic")}</p>

            <div
              style={{
                height: 272,
                overflowY: "auto"
              }}>
              {getTopics().map((topic: Topic) => (
                <TopicTaskWidget
                  key={topic.id}
                  topic={topic}
                  isActive={selectedTopic && topic.aid === selectedTopic.aid}
                  onClick={() => setSelectedTopic(topic)}
                  onInfo={() => setCurrentModal(MODAL_TOPIC)}
                />
              ))}
            </div>
          </div>

          <div className="width-40" />

          <div className="flex1">
            {isTopicTasksLoading ? (
              <PageLoader status={STATUS_LOADING} />
            ) : topicTasks.length > 0 ? (
              <div style={{ minHeight: 292 }}>
                <p className="grey-t">{t("tasks_suggestions")}</p>

                {topicTasks.map((topicTask, i) => (
                  <ListItem key={i} isEditable onClick={() => selectTopicTask(topicTask)}>
                    <div className="flex flex1" style={{ margin: "0px -32px" }}>
                      <div className="flex1">{topicTask.text}</div>

                      <div className="width-20" />

                      <div
                        className="grey-t"
                        style={{
                          width: 120,
                          fontSize: 12,
                          textAlign: "right"
                        }}>
                        {topicTask.UserResponsibles.map((user, j) => (
                          <div key={j}>{user}</div>
                        ))}
                      </div>
                    </div>
                  </ListItem>
                ))}

                <ListItem>
                  <div className="flex flex1" style={{ margin: "0px -32px" }}>
                    <Space />
                    <Button className="secondary" onClick={() => setTopicTasks([])} isFullWidth isWithBorder>
                      {t("tasks_create_new")}
                    </Button>
                  </div>
                </ListItem>
              </div>
            ) : (
              <FadeIn>
                <p className="grey-t">{t("tasks_list_title")}</p>

                <TextInput value={currentTask.title} onChange={(e: any) => editCurrentTask("title", e.value)} />

                <p className="grey-t">{t("tasks_list_text")}</p>

                <textarea
                  style={{
                    resize: "none",
                    height: "240px",
                    width: "calc(100% - 20px)",
                    border: "none",
                    padding: "6px 10px",
                    backgroundColor: "#eaeaea",
                    borderRadius: "8px"
                  }}
                  autoFocus
                  onChange={(e: any) => editCurrentTask("text", e.target.value)}
                  value={currentTask.text}></textarea>
              </FadeIn>
            )}
          </div>
        </div>
      )}
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  _session: state._session,
  actionPlan: state.actionPlan,
  topic: state.topic,
  user: state.user
})

export default connect(mapStateToProps)(withTranslation()(TasksEditModal))
