/**
 * GET-BILLINGS
 * Get the array of the history of transactions
 * Mix of subscriptions and packs of invitation
 */

import { store } from "@/index"
import { sortBy } from "lodash"
import { Subscription } from "@/redux/subscription.types"

const getBillings: Function = (): Subscription[] => {
  const billings: Subscription[] = []

  store.getState().subscription.list.forEach((subscription: Subscription) => {
    billings.push(new Subscription(subscription))
  })

  return sortBy(billings, "dateStart").reverse()
}

export default getBillings
