/**
 * TASKS-LINKER.WIDGET
 * Display new created tasks from /dashboard/questions page
 */

import { Session } from "@/redux/_session.types"
import { ActionPlanState } from "@/redux/action-plan.types"
import { faListCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import AnimatedColorSvgWidget from "./animated-color-svg.widget"
import ModalHelp from "@/components/modal-help"
import Space from "@/components/space"
import TasksListWidget from "./tasks-list.widget"
import TasksActionsWidget from "./tasks-actions.widget"
import { STATUS_SAVING } from "@/redux/_status.types"
import { store } from ".."
import { actionPlanUpdateAndStatus } from "@/redux/action-plan.actions"

interface StateProps {
  _session: Session
  actionPlan: ActionPlanState
}

interface OwnProps {
  surveyId?: string
}

type Props = StateProps & OwnProps & WithTranslation

function TasksLinkerWidget(props: Props) {
  const { t } = props

  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [isAnimated] = useState<boolean>(true)
  const [isAnimatedCounter, setIsAnimatedCounter] = useState<boolean>(false)

  //Detect changes in order to save
  useEffect(() => {
    if (props.actionPlan.status === STATUS_SAVING) {
      update()
    }

    async function update() {
      store.dispatch(actionPlanUpdateAndStatus())
    }
  }, [props.actionPlan.status])

  useEffect(() => {
    if (props.actionPlan.active.tasks.length > 0) {
      setIsAnimatedCounter(true)
    }

    const timeout: NodeJS.Timeout = setTimeout(() => {
      setIsAnimatedCounter(false)
    }, 2000)

    return () => {
      clearTimeout(timeout)
    }
  }, [props.actionPlan.active.tasks.length])

  return props._session.modules.task && props.actionPlan.active.tasks.length > 0 ? (
    <div>
      <AnimatedColorSvgWidget />

      {isExpanded ? (
        <ModalHelp onClose={() => setIsExpanded(false)} isLarge>
          <div className="flex">
            <div className="flex1">
              <b>{t("tasks_new_admin", { name: props._session.userName })}</b>
            </div>
            <TasksActionsWidget />
          </div>
          <div style={{ marginTop: -32 }}>
            <TasksListWidget />
          </div>
        </ModalHelp>
      ) : (
        <div
          className={"_hover" + (isAnimated ? " _animated" : undefined)}
          onClick={() => setIsExpanded(true)}
          title={t("tasks")}
          style={{
            position: "fixed",
            borderTop: "1px solid transparent",
            borderBottom: "1px solid transparent",
            borderLeft: "1px solid transparent",
            right: 0,
            top: "40%",
            width: 50,
            zIndex: 1,
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8
          }}>
          <div
            style={{
              backgroundColor: "white",
              padding: "10px",
              borderTopLeftRadius: 8,
              borderBottomLeftRadius: 8
            }}>
            <FontAwesomeIcon icon={faListCheck} className={isAnimated ? "list-icon-animation" : undefined} />

            <div
              className="abs red flex flex-dcol"
              style={{
                top: -10,
                paddingTop: 1,
                right: 12,
                height: 20,
                width: 21,
                borderRadius: "50%",
                textAlign: "center",
                color: "white",
                fontSize: 12,
                zIndex: 1
              }}>
              <Space />
              <div className={isAnimatedCounter ? "_vertical-shake" : ""}>{props.actionPlan.active.tasks.length}</div>
              <Space />
            </div>
          </div>
        </div>
      )}
    </div>
  ) : null
}

const mapStateToProps = (state) => ({
  _session: state._session,
  actionPlan: state.actionPlan
})

export default connect(mapStateToProps)(withTranslation()(TasksLinkerWidget))
