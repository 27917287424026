/**
 * DASHBOARD-TOPIC.WIDGET
 * Display results for a topic
 */

import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import Card from "@/components/card"
import { Question } from "@/redux/question.types"
import { Topic } from "@/redux/topic.types"
import { Axis } from "@/redux/axis.types"
import ListItem from "@/components/list-item"
import { FadeIn } from "@/utils/animations.utils"
import { sortBy } from "lodash"
import { SurveyState } from "@/redux/survey.types"
import Chip from "@/components/chip"
import { Session } from "@/redux/_session.types"
import Space from "@/components/space"
import DashboardQuestionOpenWidget from "./dashboard-question-open.widget"
import { Proposition } from "@/redux/proposition.types"
import DashboardTopicHeaderWidget from "./dashboard-topic-header.widget"
import DashboardPropositionWidget from "./dashboard-proposition.widget"
import Link from "@/components/link"
import { useEffect, useState } from "react"
import DashboardEditAfterLaunchWidget from "./dashboard-edit-after-launch.widget"
import { store } from "@/index"
import { questionActivate } from "@/redux/question.actions"
import AiAssistantButton from "@/components/ai-assistant-button"
import TopicRepartition from "@/components/topic-repartition"
import Modal from "@/components/modal"
import TasksCreateChoiceWidget from "./tasks-create-choice.widget"
import ListButton from "@/components/list-button"
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons"
import TasksGenerateModal from "@/modals/tasks-generate.modal"
import { ActionPlanState, ActionPlanTask } from "@/redux/action-plan.types"

interface StateProps extends WithTranslation {
  _session: Session
  actionPlan: ActionPlanState
  survey: SurveyState
}

interface OwnProps {
  currentAxis: Axis
  currentTopic: Topic
  isEditable?: boolean //show option to reverse question (only for supervisors)
  isOpen?: boolean
  onOpen?: Function
  actionPlanPage?: boolean //if not in dashboard display button to add task instead of ai assistant button
}

type Props = StateProps & OwnProps

const MODAL_TASK_ADD: string = "MODAL_TASK_ADD"
const MODAL_TASK_GENERATE: string = "MODAL_TASK_GENERATE"

function DashboardTopicWidget(props: Props) {
  const { t } = props

  const CLICK_ASSISTANT: "MODAL_TASK" | "QUESTION_TIPS" = props._session.modules.task ? "MODAL_TASK" : "QUESTION_TIPS"

  //Display option to edit the question once the survey is launch
  //For superadmin only
  const [showEditAfterLaunch, setShowEditAfterLaunch] = useState(false)

  //Axis
  const [axis, setAxis] = useState<Axis>(props.currentAxis)

  //Topic
  const [topic, setTopic] = useState<Topic>(props.currentTopic)

  const [currentModal, setCurrentModal] = useState<string | null>(null)

  const [isTasked, setIsTasked] = useState<boolean>(
    props.actionPlan.active.tasks.some((task: ActionPlanTask) => task.topicAid === topic.aid)
  )

  useEffect(() => {
    setTopic(props.currentTopic)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentTopic.id, props.currentTopic.open, props.currentTopic.note])

  useEffect(() => {
    setAxis(props.currentAxis)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentAxis.id, props.currentAxis.open, props.currentAxis.note])

  useEffect(() => {
    if (!isTasked) {
      setIsTasked(props.actionPlan.active.tasks.some((task: ActionPlanTask) => task.topicAid === topic.aid))
    }
  }, [props.actionPlan.active.tasks])

  //Open topic
  //Detect primary question in the list and update it if needed
  async function openTopic() {
    if (props.onOpen) {
      props.onOpen()
    }
  }

  return !topic.open ? (
    <DashboardTopicHeaderWidget onOpen={props.onOpen} currentTopic={topic} currentAxis={axis} />
  ) : (
    <FadeIn>
      {currentModal === MODAL_TASK_ADD && (
        <Modal title={t("tasks_list_add")} onClose={() => setCurrentModal(null)}>
          <TasksCreateChoiceWidget selectedTopic={topic} onClose={() => setCurrentModal(null)} isInModal />
        </Modal>
      )}

      {currentModal === MODAL_TASK_GENERATE && (
        <TasksGenerateModal
          selectedTopic={topic}
          onClose={() => setCurrentModal(null)}
          surveyId={props.survey.active.id}
        />
      )}

      <div className="detail-topic-card" id={"details-topic-card-" + topic.id}>
        <Card isWithoutPadding exportName={topic.label}>
          <ListItem key={topic.aid} isEditable onClick={() => openTopic()}>
            <div className="flex1">
              <DashboardTopicHeaderWidget
                onOpen={props.onOpen}
                hideProgressBar={props._session.accountOptions.dashboardDisplayMode === "satisfaction"}
                currentTopic={topic}
                currentAxis={axis}
              />
            </div>
          </ListItem>

          {topic.hide ? (
            <ListItem>
              <div className="grey-t">
                {topic.usersCount +
                  " " +
                  t(topic.usersCount > 1 ? "propositions" : "proposition") +
                  ". " +
                  t("survey_no_results_help", {
                    count: props._session.participationMin
                  })}
              </div>
            </ListItem>
          ) : (
            topic.Questions.map((question, i) =>
              question.type === "open" ? (
                <DashboardQuestionOpenWidget
                  key={question.id}
                  count={question.messagesCount}
                  currentTopic={topic}
                  currentQuestion={question}
                />
              ) : (
                <ListItem key={question.id}>
                  <div className="flex1">
                    <div className="flex">
                      {topic.Questions.length > 1 && (
                        <Chip color={!question.primaryId ? axis.color : ""}>
                          {t("question_" + (!question.primaryId ? "primary" : "secondary"))}
                        </Chip>
                      )}

                      {topic.Questions.length > 1 && <div className="width-20" />}

                      <span className={question.primaryId ? "grey-t" : ""}>{question.label}</span>

                      {!question.primaryId && (
                        <>
                          {props.actionPlanPage ? (
                            <div className="flex">
                              <div className="width-20" />
                              <ListButton
                                isPrimary
                                isFat
                                text={t("tasks_list_add")}
                                icon={faCirclePlus}
                                onClick={() => setCurrentModal(MODAL_TASK_ADD)}
                              />
                            </div>
                          ) : //On graduation-cap button click
                          //use chat gpt to get advices on the question
                          //should animate if (props._session.modules.assistant || credits remaining as free user) && !props._session.accountOptions.allowAssistant
                          props._session.modules.assistant ? (
                            <div style={{ minWidth: 180 }} className="flex">
                              <Space />
                              <div className="flex flex-dcol" data-tip={isTasked ? t("tasks_topic_used") : undefined}>
                                <AiAssistantButton
                                  isAnimated={!isTasked}
                                  isChecked={isTasked}
                                  onClick={() =>
                                    CLICK_ASSISTANT === "QUESTION_TIPS"
                                      ? store.dispatch(questionActivate(new Question(question)))
                                      : setCurrentModal(MODAL_TASK_GENERATE)
                                  }
                                />
                                <Space />
                              </div>
                            </div>
                          ) : (
                            <div />
                          )}
                        </>
                      )}

                      <Space />

                      <div className="width-20" />

                      {question.type === "scale" && question.usersCount > 0 && (
                        <span className="grey-t" style={{ minWidth: 90 }}>
                          {question.usersCount} {question.usersCount > 1 ? t("propositions") : t("proposition")}
                        </span>
                      )}
                    </div>

                    {
                      /** show text if trigger */
                      question.getTrigger(question.getQuestionPrimary(topic.Questions)) && question.primaryId && (
                        <div className="details-question-trigger grey-t">
                          <i>
                            {t("question_answer_at_alt")}&nbsp;
                            <b>
                              {question.getTriggerLabel(
                                props.survey.active.template,
                                question.getQuestionPrimary(topic.Questions),
                                props._session.language
                              )}
                            </b>
                            &nbsp;
                            {t("question_answer_at_question_name", {
                              question: question.getQuestionPrimary(topic.Questions).label
                            })}
                          </i>
                        </div>
                      )
                    }

                    {question.usersCount === 0 && question.type === "scale" && (
                      <p style={{ margin: "22px 0px" }} className="grey-t">
                        <b>
                          {t("questions_no_results", {
                            count: props._session.participationMin
                          })}
                        </b>
                      </p>
                    )}

                    {question.type === "choice" && (
                      <div className="flex">
                        <Space />
                        <div className="details-proposition-container">
                          {sortBy(question.Propositions, "count")
                            .reverse()
                            .map((proposition) => (
                              <DashboardPropositionWidget
                                key={proposition.id}
                                currentAxis={axis}
                                currentProposition={proposition}
                              />
                            ))}

                          {question.withMessage && (
                            <div>
                              <DashboardPropositionWidget
                                currentAxis={axis}
                                currentProposition={new Proposition({ id: "others", count: question.messagesCount })}
                              />

                              <div className="height-20" />

                              {question.Messages.length > 0 && (
                                <p>
                                  {props._session.accountId === "6654c17d-5678-4277-a4ff-04d43b255fa5" &&
                                  props._session.userRole === "OBSERVER" ? null : (
                                    <b>
                                      {t("utils_details")} ({t("utils_others")})
                                    </b>
                                  )}
                                </p>
                              )}

                              <div className="height-20" />

                              <DashboardQuestionOpenWidget
                                key={question.id}
                                count={question.messagesCount}
                                currentTopic={topic}
                                currentQuestion={question}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {question.type !== "choice" && question.repartition.length > 0 && (
                      <TopicRepartition
                        id={question.id}
                        repartition={question.repartition}
                        noAnswers={question.noAnswers}
                        reverse={question.reverse}
                        isLarge={i === 0}
                      />
                    )}

                    {props.isEditable && props._session.connectedAsSupervisor && question.type === "scale" && (
                      <div className="flex">
                        <Space />
                        <Link onClick={() => setShowEditAfterLaunch(!showEditAfterLaunch)}>
                          <span style={{ fontSize: 12 }}>Modifier après enquête (Gestionnaire IDTree uniquement)</span>
                        </Link>
                      </div>
                    )}

                    {showEditAfterLaunch && <DashboardEditAfterLaunchWidget currentQuestion={question} />}
                  </div>
                </ListItem>
              )
            )
          )}
        </Card>
      </div>
    </FadeIn>
  )
}

const mapStateToProps = (state) => ({
  _session: state._session,
  actionPlan: state.actionPlan,
  survey: state.survey
})

export default connect(mapStateToProps)(withTranslation()(DashboardTopicWidget))
