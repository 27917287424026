/**
 * SETTINGS.WIDGET
 * Parameters for the account
 */

import { store } from "@/index"
import { withTranslation, WithTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { accountEdit, accountStatus, accountUpdate } from "@/redux/account.actions"
import { AccountState } from "@/redux/account.types"
import {
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_LOAD_ERROR,
  STATUS_SAVED,
  STATUS_SAVE_ERROR,
  STATUS_SAVING
} from "@/redux/_status.types"
import { connect } from "react-redux"
import ListItem from "@/components/list-item"
import SaveIcon from "@/components/save-icon"
import Space from "@/components/space"
import { Session, SESSION_COLORS } from "@/redux/_session.types"
import { toast } from "react-toastify"
import PageLoader from "@/components/page-loader"
import DeleteAccountModal from "@/modals/delete-account.modal"
import PageHeader from "@/components/page-header"
import Button from "@/components/button"
import Tabs from "@/components/tabs"
import Card from "@/components/card"
import TextInput from "@/components/text-input"
import { sessionEdit, sessionEditAccountColors, sessionEditAccountOptions } from "@/redux/_session.actions"
import { subscriptionFetch, subscriptionGet } from "@/redux/subscription.actions"
import ModulesWidget from "./modules.widget"
import Link from "@/components/link"
import ColorPickerWidget from "./color-picker.widget"
import ImagePicker from "@/components/image-picker"
import AccountOptionsWidget from "./account-options.widget"
import BuildInfoWidget from "./build-info.widget"
import Checkbox from "@/components/checkbox"
import env from "@/env"
import ColorsPreviewWidget from "./colors-preview.widget"
import getBillings from "@/utils/get-billings.utils"
import BillingsWidget from "./billings.widget"
import LoginModal from "@/modals/login.modal"

interface StateProps {
  _session: Session
  account: AccountState
}

type Props = StateProps & WithTranslation

//Modals
const MODAL_DELETE: string = "MODAL_DELETE"
const MODAL_LOGIN_SUPERADMIN: string = "MODAL_LOGIN_SUPERADMIN"

//List of tabs
const TAB_GENERAL: string = "TAB_GENERAL"
const TAB_SECURITY: string = "TAB_SECURITY"
const TAB_SKIN: string = "TAB_SKIN"
const TAB_PREFERENCES: string = "TAB_PREFERENCES"
const TAB_BUILD: string = "TAB_BUILD"

function AdmSettingsWidget(props: Props) {
  const { t } = props

  const [currentModal, setCurrentModal] = useState<string | null>(null)
  const [currentTab, setCurrentTab] = useState(getInitialTab())

  useEffect(() => {
    loadPage()

    //Load all the data of the page
    //Packs + subscriptions + remaining invitations
    async function loadPage() {
      store.dispatch(accountStatus(STATUS_LOADING))
      const subscriptions: any = await store.dispatch(subscriptionFetch())

      if (subscriptions.error) {
        store.dispatch(accountStatus(STATUS_LOAD_ERROR))
      } else {
        store.dispatch(accountStatus(STATUS_LOADED))
        store.dispatch(subscriptionGet(subscriptions))
      }
    }
  }, [])

  function editPrimaryColor(color: string) {
    saveColor("brandPrimary", color)

    if (props._session.interfaceType === "SUPERADMIN") {
      store.dispatch(sessionEdit("superaccountColor", color))
    }
  }

  //Edit account name
  function handleChanges(event) {
    store.dispatch(sessionEdit(event.id, event.value))
  }

  //Set security
  function handleIsPasswordRequired() {
    store.dispatch(sessionEdit("isPasswordRequired", !props._session.isPasswordRequired))
    setTimeout(() => {
      saveAccount(props._session.accountOptions)
    }, 500)
  }

  //Save data to database
  async function saveAccount(options) {
    const account = {
      id: props._session.accountId,
      name: props._session.accountName,
      options,
      colors: props._session.accountColors,
      isPasswordRequired: props._session.isPasswordRequired
    }

    store.dispatch(accountStatus(STATUS_SAVING))
    const response: any = await store.dispatch(accountUpdate(account))
    store.dispatch(accountStatus(response.error ? STATUS_SAVE_ERROR : STATUS_SAVED))

    if (!response.error) {
      toast(t("utils_saved"), { autoClose: 2000 })
    }
  }

  //Save color
  function saveColor(id, color) {
    store.dispatch(sessionEditAccountColors(id, color))
    store.dispatch(accountEdit("colors", { ...props._session.accountColors, [id]: color }))
    setTimeout(() => {
      saveAccount(props._session.accountOptions)
    }, 500)
  }

  //Save options
  //Timeout of 1000ms before save
  function saveImage(value) {
    const options = Object.assign({}, props._session.accountOptions, { image: value })
    store.dispatch(sessionEdit("accountOptions", options))

    setTimeout(() => {
      saveAccount(options)

      if (props._session.interfaceType === "SUPERADMIN") {
        store.dispatch(sessionEdit("superaccountImage", value))
      }
    }, 500)
  }

  //Disable the chat widget
  function toogleChat() {
    store.dispatch(sessionEditAccountOptions("disableChat", !props._session.accountOptions.disableChat))
    setTimeout(() => {
      saveAccount(props._session.accountOptions)
      window.location.href = window.location.href + "?modal=TAB_SKIN"
    }, 500)
  }

  return (
    <div>
      {currentModal === MODAL_DELETE && (
        <DeleteAccountModal
          accountId={props._session.accountId}
          isAsSuperadmin={props._session.connectedAsSuperadmin && props._session.accountIsChildren}
          onClose={() => setCurrentModal(null)}
          onDelete={props._session.connectedAsSuperadmin ? () => setCurrentModal(MODAL_LOGIN_SUPERADMIN) : undefined}
        />
      )}

      {currentModal === MODAL_LOGIN_SUPERADMIN && (
        <LoginModal
          authLevel={2}
          onClose={() => setCurrentModal(null)}
          email={props._session.email}
          accountIdForSuperadmin={props._session.connectedAsSupervisor ? props._session.superaccountId : undefined}
          isSupervisor={props._session.connectedAsSupervisor}
        />
      )}

      <PageHeader title={props._session.interfaceType === "SUPERADMIN" ? t("utils_params") : t("account_settings")}>
        <Button onClick={() => setCurrentModal(MODAL_DELETE)}>
          {props._session.interfaceType === "SUPERADMIN" ? t("account_delete_superadmin") : t("account_delete")}
        </Button>
      </PageHeader>

      <Tabs
        active={currentTab}
        onClick={(tab) => setCurrentTab(tab)}
        tabs={{
          TAB_GENERAL: t("account_settings_general"),
          TAB_SECURITY: t("account_settings_security"),
          TAB_SKIN: t("account_settings_skin"),
          TAB_PREFERENCES: t("account_settings_preferences"),
          TAB_BUILD: t("account_settings_build")
        }}
      />

      <PageLoader status={props.account.status}>
        {currentTab === TAB_GENERAL && (
          <Card>
            <TextInput
              id="accountName"
              onBlur={() => saveAccount(props._session.accountOptions)}
              onChange={handleChanges}
              status={props.account.status}
              title={t("account_create_name")}
              value={props._session.accountName}
            />

            <div className="height-20" />

            <div className="flex">
              <div className="flex1">
                <h3>{t("account_modules")}</h3>
                <ModulesWidget modules={props._session.modules} readonly />
                <div style={{ height: "8px" }} />
                <Link isWithoutMargin onClick={() => window.open("https://www.id-tree.com/lp-contact", "_blank")}>
                  {t("utils_contact")}
                </Link>
              </div>

              <div className="width-20" />

              <div className="flex1">
                <h3>{t("account_invitations")}</h3>
                <p>{props._session.accountInvitations}</p>
                <Link isWithoutMargin onClick={() => window.open("https://www.id-tree.com/lp-contact", "_blank")}>
                  {t("utils_contact")}
                </Link>
              </div>
            </div>

            <div className="height-20" />

            {getBillings().length > 0 && (
              <BillingsWidget billings={getBillings()} showPaymentStatus title={t("account_billing")} />
            )}
          </Card>
        )}

        {currentTab === TAB_SECURITY && (
          <div>
            <p className="grey-t">{t("admin_security_help")}</p>

            <div className="height-20" />

            <Card isWithoutPadding>
              {props._session.modules.sso ? (
                <ListItem>
                  <div className="flex1">
                    <p>
                      <b>{t("admin_sso_activated")}</b>
                    </p>

                    <div className="height-20" />

                    <Checkbox
                      active={props._session.accountOptions.customEmailSenderInstance}
                      text={t("admin_custom_email_sender_instance")}
                      onClick={(e) => {
                        store.dispatch(sessionEditAccountOptions("customEmailSenderInstance", e.value))

                        setTimeout(() => {
                          saveAccount(props._session.accountOptions)
                        }, 500)
                      }}
                      status={props.account.status}
                    />
                  </div>

                  <TextInput
                    id="ssoOrganization"
                    onBlur={() => saveAccount(props._session.accountOptions)}
                    onChange={(e) => store.dispatch(sessionEditAccountOptions("ssoOrganization", e.value))}
                    status={props.account.status}
                    title={t("account_sso_organization")}
                    value={props._session.accountOptions.ssoOrganization}
                  />
                </ListItem>
              ) : (
                <ListItem>
                  <div>
                    <p>
                      <b>{t("admin_security_admin_level")}</b>
                    </p>
                    <p className="grey-t">{t("admin_security_admin_level_help")}</p>

                    <div className="height-10" />

                    <Checkbox
                      active={props._session.isPasswordRequired}
                      text={t("admin_security_level")}
                      onClick={handleIsPasswordRequired}
                      status={props.account.status}
                    />

                    <div className="height-10" />
                  </div>
                </ListItem>
              )}
            </Card>
          </div>
        )}

        {currentTab === TAB_SKIN && (
          <Card>
            <div className="flex">
              <div>
                <h3>{t("account_logo")}</h3>

                <ImagePicker
                  id={props._session.accountId}
                  square
                  backgroundColor={props._session.accountColors.brandPrimary}
                  imageId={props._session.accountOptions.image ? props._session.accountId : null}
                  model="Accounts"
                  onDelete={() => saveImage(false)}
                  onSave={() => saveImage(true)}
                />
              </div>

              <Space />

              <div className="width-100" style={{ margin: 0 }}>
                <ColorsPreviewWidget account={props.account.active} />
              </div>
            </div>

            <div className="height-20" />

            <div className="flex">
              <h3>{t("utils_colors")}</h3>
              <Space />
              <SaveIcon status={props.account.status} />
            </div>

            <div className="flex">
              <div className="flex1">
                <ColorPickerWidget
                  colors={SESSION_COLORS}
                  isCustom
                  title={t("account_color_primary")}
                  selectedColor={props._session.accountColors.brandPrimary}
                  onSelect={(color: string) => editPrimaryColor(color)}
                  imageUrl={
                    props._session.accountOptions.image
                      ? env.REACT_APP_URL_SPACE.concat(
                          "/Accounts/",
                          props._session.accountId,
                          ".png?" + new Date().getTime()
                        )
                      : undefined
                  }
                />

                <ColorPickerWidget
                  colors={SESSION_COLORS}
                  isCustom
                  title={t("account_color_secondary")}
                  selectedColor={props._session.accountColors.brandSecondary}
                  onSelect={(color: string) => saveColor("brandSecondary", color)}
                  imageUrl={
                    props._session.accountOptions.image
                      ? env.REACT_APP_URL_SPACE.concat(
                          "/Accounts/",
                          props._session.accountId,
                          ".png?" + new Date().getTime()
                        )
                      : undefined
                  }
                />

                <ColorPickerWidget
                  colors={SESSION_COLORS}
                  isCustom
                  title={t("account_color_text")}
                  selectedColor={props._session.accountColors.brandText}
                  onSelect={(color: string) => saveColor("brandText", color)}
                  imageUrl={
                    props._session.accountOptions.image
                      ? env.REACT_APP_URL_SPACE.concat(
                          "/Accounts/",
                          props._session.accountId,
                          ".png?" + new Date().getTime()
                        )
                      : undefined
                  }
                />
              </div>

              <div className="flex1">
                <ColorPickerWidget
                  colors={SESSION_COLORS}
                  isCustom
                  title={t("account_color_cta")}
                  selectedColor={props._session.accountColors.cta}
                  onSelect={(color: string) => saveColor("cta", color)}
                  imageUrl={
                    props._session.accountOptions.image
                      ? env.REACT_APP_URL_SPACE.concat(
                          "/Accounts/",
                          props._session.accountId,
                          ".png?" + new Date().getTime()
                        )
                      : undefined
                  }
                />

                <ColorPickerWidget
                  colors={SESSION_COLORS}
                  isCustom
                  title={t("account_color_active")}
                  selectedColor={props._session.accountColors.active}
                  onSelect={(color: string) => saveColor("active", color)}
                  imageUrl={
                    props._session.accountOptions.image
                      ? env.REACT_APP_URL_SPACE.concat(
                          "/Accounts/",
                          props._session.accountId,
                          ".png?" + new Date().getTime()
                        )
                      : undefined
                  }
                />

                <ColorPickerWidget
                  colors={SESSION_COLORS}
                  isCustom
                  title={t("account_color_email")}
                  selectedColor={props._session.accountColors.email}
                  onSelect={(color: string) => saveColor("email", color)}
                  imageUrl={
                    props._session.accountOptions.image
                      ? env.REACT_APP_URL_SPACE.concat(
                          "/Accounts/",
                          props._session.accountId,
                          ".png?" + new Date().getTime()
                        )
                      : undefined
                  }
                />
              </div>
            </div>

            {
              /** chat option only if language is french */
              props._session.language === "fr" && (
                <div className="flex" style={{ marginTop: 32 }}>
                  <Checkbox
                    active={!props._session.accountOptions.disableChat}
                    text={t("account_settings_chat")}
                    onClick={toogleChat}
                  />
                  <Space />
                </div>
              )
            }
          </Card>
        )}

        {currentTab === TAB_PREFERENCES && <AccountOptionsWidget />}

        {currentTab === TAB_BUILD && (
          <Card>
            <BuildInfoWidget />
          </Card>
        )}
      </PageLoader>
    </div>
  )
}

//Get initial tab (if reload from preference stay on tab)
function getInitialTab() {
  let destination = TAB_GENERAL

  //Get URL params
  const searchParams = new URLSearchParams(window.location.search)
  const modal = searchParams.get("modal")

  if (modal === TAB_PREFERENCES) {
    destination = TAB_PREFERENCES
  } else if (modal === TAB_SKIN) {
    destination = TAB_SKIN
  }

  //Remove params
  window.history.pushState({}, "", window.location.pathname)

  //REturn tab
  return destination
}

const mapStateToProps = (state) => ({
  _session: state._session,
  account: state.account
})

export default connect(mapStateToProps)(withTranslation()(AdmSettingsWidget))
