/***
 * CONTACT-PHONE.WIDGET
 * Display number
 */
import Space from "@/components/space"
import { useState } from "react"
import Chip from "@/components/chip"
import { connect } from "react-redux"
import { withTranslation, WithTranslation } from "react-i18next"
import { Session } from "@/redux/_session.types"
import { faColumns, faPaperPlane, faPhone } from "@fortawesome/free-solid-svg-icons"
import ContextMenu from "@/components/context-menu"
import ListItem from "@/components/list-item"
import Link from "@/components/link"
import ContactModal from "@/modals/contact.modal"
import i18n from "@/translate/i18n"

const MODAL_CONTACT = "MODAL_CONTACT"

interface StateProps extends WithTranslation {
  _session: Session
}

function AdmInvitationsRemainingWidget(props: StateProps) {
  const { t } = props

  //Modal to display
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  //Show if menu is open
  const [isOpen, setIsOpen] = useState(false)

  return props._session.userRole === "ADMIN" ? (
    <div style={{ marginRight: 18 }} className="flex flex-dcol rel">
      {currentModal === MODAL_CONTACT && <ContactModal onClose={() => setCurrentModal(null)} />}

      <Space />

      {props._session.accountLevel === "free" ? (
        <Chip isOutlineColored onClick={() => setIsOpen(!isOpen)} color={props._session.accountColors.brandSecondary}>
          {t("account_app_level_free")}
        </Chip>
      ) : (
        <Chip
          isOutlineColored
          onClick={() => setIsOpen(!isOpen)}
          color={props._session.accountColors.brandSecondary}
          icon={faPaperPlane}>
          {props._session.accountInvitations + ""}
        </Chip>
      )}

      <Space />

      {isOpen && (
        <ContextMenu onClose={() => setIsOpen(false)} positionRight>
          <ListItem>
            <div style={{ fontSize: 12 }}>
              {props._session.accountLevel !== "free" && (
                <div style={{ marginBottom: 10 }}>
                  {t("account_invitations_help", {
                    count: props._session.accountInvitations,
                    s: props._session.accountInvitations > 1 ? "s" : ""
                  })}
                </div>
              )}

              {t("account_upgrade")}

              {props._session.accountLevel === "free" && (
                <div className="grey-t">
                  <div className="height-10" />
                  {t("account_invitations_remaining_free")}
                </div>
              )}
            </div>
          </ListItem>
          <ListItem isSmall>
            {t("account_app_level")} :&nbsp;<b>{t("account_app_level_" + props._session.accountLevel)}</b>
          </ListItem>
          <ListItem isSmall isEditable>
            <Link isWithoutMargin onClick={() => setCurrentModal(MODAL_CONTACT)} icon={faPhone}>
              {t("utils_contact")}
            </Link>
          </ListItem>
          {i18n.language === "fr" && (
            <ListItem isSmall isEditable>
              <Link
                isWithoutMargin
                onClick={() => window.open("https://id-tree.com/tarifs", "_blank")}
                icon={faColumns}>
                {t("utils_prices")}
              </Link>
            </ListItem>
          )}
        </ContextMenu>
      )}
    </div>
  ) : null
}

const mapStateToProps = (state) => ({
  _session: state._session
})

export default connect(mapStateToProps)(withTranslation()(AdmInvitationsRemainingWidget))
