/**
 * ADM-SEND-EMAIL.MODAL
 * Send invitation for an admin or observer
 */

import { connect } from "react-redux"
import { withTranslation, WithTranslation } from "react-i18next"
import { User } from "@/redux/user.types"
import { useState } from "react"
import { store } from "@/index"
import { userEditAndUpdate, userInvite } from "@/redux/user.actions"
import { toast } from "react-toastify"
import ModalConfirm from "@/components/modal-confirm"
import { STATUS_LOADED, STATUS_SAVING } from "@/redux/_status.types"
import { Session } from "@/redux/_session.types"

interface StateProps extends WithTranslation {
  _session: Session
}

interface OwnProps {
  currentUser: User
  onClose: Function
  text: string
  textBold: string
}

type Props = StateProps & OwnProps

function AdmSendEmailModal(props: Props) {
  const { t } = props

  const [isSaving, setIsSaving] = useState(false)

  //Get email invitation code (for the backennd)
  function getEmailCode() {
    switch (props.currentUser.role) {
      case "ADMIN":
        return "admInviteAdmin"
      case "MANAGER":
        return "admInviteManager"
      default:
        return "admInviteObserver"
    }
  }

  //Send an email to the user
  async function sendEmailConfirm() {
    setIsSaving(true)
    const response: any = await store.dispatch(
      userInvite(
        props.currentUser.id,
        props._session.userName,
        props.currentUser.getInvitationLink(props._session),
        getEmailCode(),
        props.currentUser.populationsObserver.map((x) => x.filterName + " : " + x.name).join(", ")
      )
    )
    if (!response.error) {
      toast(t("utils_email_send", { email: props.currentUser.email }))
      if (!props.currentUser.invitedAt) {
        store.dispatch(userEditAndUpdate(props.currentUser, "invitedAt", new Date().getTime()))
      }

      props.onClose()
    }
  }

  return (
    <ModalConfirm
      onNo={() => props.onClose(null)}
      onYes={sendEmailConfirm}
      status={isSaving ? STATUS_SAVING : STATUS_LOADED}
      textBold={props.textBold}
      text={props.text}
    />
  )
}

const mapStateToProps = (state) => ({
  _session: state._session
})

export default connect(mapStateToProps)(withTranslation()(AdmSendEmailModal))
