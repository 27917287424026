/**
 * _HOME-ACCOUNT
 * Hub for the application
 */
import NavApp from "@/nav/nav.app"
import HomeSurveysWidget from "@/widgets/home-surveys.widget"
import Space from "@/components/space"
import HomeTemplatesWidget from "@/widgets/home-templates.widget"
import HomeResourcesWidget from "@/widgets/home-resources.widget"
import { Session } from "@/redux/_session.types"
import { connect } from "react-redux"
import HomeCalendarWidget from "@/widgets/home-calendar.widget"
import HomeLastInvitationWidget from "@/widgets/home-last-invitation.widget"
import HomeAccountsWidget from "@/widgets/home-accounts.widget"

interface StateProps {
  _session: Session
}

function HomeAccountRoute(props: StateProps) {
  return props._session.userRole === "ADMIN" ? (
    <NavApp>
      <div className="flex">
        <Space />

        {props._session.interfaceType === "SUPERADMIN" ? (
          <div style={{ marginTop: "14px" }} className="flex flex1">
            <div>
              <HomeTemplatesWidget />
              <HomeResourcesWidget />
            </div>

            <div className="width-20" />

            <div className="flex1 flex" style={{ width: 460 }}>
              <HomeAccountsWidget />
            </div>
          </div>
        ) : (
          <div className="flex" style={{ marginTop: 40 }}>
            <div style={{ width: 400 }} className="flex">
              <HomeCalendarWidget />
            </div>

            <div className="width-20" />

            <div className="flex1">
              <HomeSurveysWidget />
              <div className="flex">
                <HomeTemplatesWidget />
                <div className="width-20" />
                <HomeResourcesWidget />
              </div>
            </div>
          </div>
        )}

        <Space />
      </div>
    </NavApp>
  ) : (
    <NavApp>
      {!props._session.userRole && <HomeLastInvitationWidget />}

      <div className="flex">
        <Space />

        {(props._session.userRole === "OBSERVER" || props._session.userRole === "MANAGER") && <HomeSurveysWidget />}

        <Space />
      </div>
    </NavApp>
  )
}

const mapStateToProps = (state) => ({
  _session: state._session
})

export default connect(mapStateToProps)(HomeAccountRoute)
