/**
 * SUPERVISOR.ACCOUNTS
 * List of IDTree account
 */

import { useEffect, useState } from "react"
import { connect } from "react-redux"
import Button from "@/components/button"
import { WithTranslation, withTranslation } from "react-i18next"
import PageHeader from "@/components/page-header"
import Card from "@/components/card"
import ListContainer from "@/components/list-container"
import { ACCOUNT_DEMO, ACCOUNT_DEMO_GROUP, Account, AccountState } from "@/redux/account.types"
import {
  accountStatus,
  accountFetch_AsSupervisor,
  accountFetchCount_AsSupervisor,
  accountGetCount,
  accountGetCountSearch,
  accountAdd,
  accountActivate,
  accountGet
} from "@/redux/account.actions"
import { store } from "@/index"
import { STATUS_LOADED, STATUS_LOADING, STATUS_LOAD_ERROR, STATUS_SEARCHING } from "@/redux/_status.types"
import ListItem from "@/components/list-item"
import ListFields from "@/components/list-fields"
import Chip from "@/components/chip"
import ListCell from "@/components/list-cell"
import Space from "@/components/space"
import AddAccountModal from "@/modals/add-account.modal"
import Modal from "@/components/modal"
import { faPencilAlt, faPlayCircle, faShieldAlt, faTag } from "@fortawesome/free-solid-svg-icons"
import ListButton from "@/components/list-button"
import Tabs from "@/components/tabs"
import ListIcon from "@/components/list-icon"
import env from "@/env"
import { subscriptionGet } from "@/redux/subscription.actions"
import PageLoader from "@/components/page-loader"
import { Session } from "@/redux/_session.types"
import LoginModal from "@/modals/login.modal"
import { userActivate } from "@/redux/user.actions"
import DeleteUserSuperModal from "@/modals/delete-user-as-supervisor.modal"
import SupervisorsListWidget from "@/widgets/supervisors-list.widget"
import { User } from "@/redux/user.types"
import NavSupervisor from "@/nav/nav.supervisor"
import ModulesWidget from "@/widgets/modules.widget"
import { Subscription } from "@/redux/subscription.types"
import { sum } from "lodash"
import SendingsAllWidget from "@/widgets/sendings-all.widget"
import { NavigateFunction, useNavigate } from "react-router-dom"
import DatabaseHostWidget from "@/widgets/database-host.widget"

interface Props extends WithTranslation {
  _session: Session
  account: AccountState
}

//Modals
const MODAL_ADD = "MODAL_ADD" //Add new account
const MODAL_AUTH_CONFIRM = "MODAL_AUTH_CONFIRM" //Confirm connexion
const MODAL_LOGIN = "MODAL_LOGIN" //Loading modal
const MODAL_DELETE_USER = "MODAL_DELETE_USER" //Display user to delete

//Tabs
//const TAB_LOW = "TAB_LOW" //See all low touch client (from self-service)
const TAB_MED = "TAB_MED" //See all med touch clients (create by supervisor)
const TAB_SELF = "TAB_SELF" //See my clients
const TAB_SEND = "TAB_SEND" //Show upcoming sendings
const TAB_USERS = "TAB_USERS" //Show admins

//Limit of account displayed by page
const LIMIT: number = 5

//Color for account
const ACCOUNT_DEMO_COLOR = "#6c547b"

function SupervisorAccounts(props: Props) {
  const { t } = props
  const navigate: NavigateFunction = useNavigate()

  const queryParams: URLSearchParams = new URLSearchParams(window.location.search)
  const tab: string | null = queryParams.get("tab")

  const [currentModal, setCurrentModal] = useState<string | null>(null)
  const [currentOffset, setCurrentOffset] = useState<number>(0)
  const [currentSearch, setCurrentSearch] = useState<string>("")
  const [currentTab, setCurrentTab] = useState<string>(tab ? tab : TAB_SELF)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  //Load account on start
  useEffect(() => {
    loadAccounts()

    //Load account
    async function loadAccounts() {
      store.dispatch(accountStatus(STATUS_SEARCHING))

      //Do query (get list and count)
      const accounts: any = await store.dispatch(
        accountFetch_AsSupervisor(currentSearch, currentOffset, LIMIT, currentTab === TAB_SELF)
      )
      const accountsCount: any = await store.dispatch(
        accountFetchCount_AsSupervisor(currentSearch, currentTab === TAB_SELF)
      )
      setIsLoaded(true)

      if (accounts.error || accountsCount.error) {
        store.dispatch(accountStatus(STATUS_LOAD_ERROR))
      } else {
        //Update list
        store.dispatch(accountStatus(STATUS_LOADED))
        store.dispatch(accountGet(accounts))

        //Update store
        store.dispatch(accountGetCountSearch(accountsCount.count))
        if (!currentSearch.length) store.dispatch(accountGetCount(accountsCount.count))
      }
    }
  }, [currentOffset, currentTab, currentSearch])

  //Add new account
  function addAccount() {
    store.dispatch(accountAdd(props._session.supervisorId, props._session.userFirstname, props._session.userLastname))
    store.dispatch(subscriptionGet([]))
    setCurrentModal(MODAL_ADD)
  }

  //Delete user
  function deleteUser(user: User) {
    store.dispatch(userActivate(user))
    store.dispatch(accountActivate(user.Account))
    setCurrentModal(MODAL_DELETE_USER)
  }

  //Clic on edit button > get subscriptions and pack
  function editAccount(account: Account) {
    store.dispatch(accountActivate(account))
    store.dispatch(subscriptionGet(account.Subscriptions))
    navigate("/supervisor/account")
  }

  //Clic on "connect to account"
  //Open dialog to confirm
  function getAccount(account: Account) {
    store.dispatch(accountActivate(account))
    setCurrentModal(MODAL_AUTH_CONFIRM)
  }

  //Access to account : open loading modal and login
  function getAccountConfirm() {
    setCurrentModal(MODAL_LOGIN)
  }

  //Get access to smartlink account
  function getAccountDemo(isMultiple: boolean) {
    getAccount(isMultiple ? ACCOUNT_DEMO_GROUP : ACCOUNT_DEMO)
  }

  //Get type of accounts
  function getAccountType(account: Account) {
    if (account.isSuperAccount) {
      return <Chip color="#8bbdd9">{t("account_super_account_parent")}</Chip>
    } else if (account.superAccountId) {
      return <Chip>{t("account_super_account_children")}</Chip>
    } else {
      return null
    }
  }

  //Get account sum of unpaid bills
  function getAccountUnpaid(subscriptions: Subscription[]) {
    return sum(subscriptions.filter((x: Subscription) => !x.paid).map((x: Subscription) => x.price))
  }

  function getTabContent() {
    switch (currentTab) {
      case TAB_SEND:
        return <SendingsAllWidget onClick={(account: Account) => getAccount(account)} />

      default:
        return (
          <SupervisorsListWidget
            onClick={(account: Account) => getAccount(account)}
            onDelete={(user: User) => deleteUser(user)}
          />
        )
    }
  }

  //Search account
  function searchAccounts(search: string) {
    setCurrentSearch(search)
    setCurrentOffset(0)
    setIsLoaded(false)
  }

  //Select tab
  //Reload account after
  function selectTab(tab: string) {
    store.dispatch(accountGet([]))

    setCurrentOffset(0)
    setCurrentTab(tab)
    if (tab !== TAB_USERS) {
      setIsLoaded(false)
    }
  }

  //Change page
  function setPage(offset: number) {
    setCurrentOffset(offset)
    setIsLoaded(false)
  }

  return (
    <NavSupervisor>
      {currentModal === MODAL_ADD && <AddAccountModal onClose={() => setCurrentModal(null)} />}

      {currentModal === MODAL_AUTH_CONFIRM && (
        <Modal isCloseButtonVisible onClose={() => setCurrentModal(null)} onNext={getAccountConfirm}>
          <p>
            {t("account_connect")} : <b>{props.account.active.name}</b>
          </p>
        </Modal>
      )}

      {currentModal === MODAL_LOGIN && (
        <LoginModal
          authLevel={2}
          isSupervisor
          accountIdForSuperadmin={props.account.active.id}
          email={props._session.email}
        />
      )}

      {currentModal === MODAL_DELETE_USER && <DeleteUserSuperModal onClose={() => setCurrentModal(null)} />}

      <PageHeader title={t("accounts")}>
        <Button className="primary" onClick={addAccount}>
          {t("account_create")}
        </Button>
      </PageHeader>

      <Tabs
        active={currentTab}
        onClick={selectTab}
        tabs={{
          TAB_SELF: t("account_self"),
          TAB_MED: t("account_med"),
          TAB_SEND: t("sendings"),
          TAB_USERS: t("user_admins_all")
        }}
      />

      {currentTab === TAB_USERS || currentTab === TAB_SEND ? (
        getTabContent()
      ) : (
        <Card isWithoutPadding isOverflowHidden>
          <ListContainer
            countLimit={LIMIT}
            countSearch={props.account.countSearch}
            isFooterActive
            onSearch={searchAccounts}
            onSearchStart={() => store.dispatch(accountStatus(STATUS_SEARCHING))}
            onSetPage={setPage}
            status={props.account.status}>
            <PageLoader status={isLoaded ? STATUS_LOADED : STATUS_LOADING}>
              {
                /**
                 * display for all accounts
                 */
                (currentTab === TAB_SELF || currentTab === TAB_MED) && (
                  <div>
                    <ListFields>
                      <ListCell />
                      <ListCell width={200} text={t("user_lastname")} />
                      <ListCell width={260} text={t("project")} />
                      <Space />
                      <ListCell width={120} text={t("unpaid_bills")} />
                      <ListCell width={160} text={t("account_super_account")} />
                      <ListCell width={180} text={t("account_modules")} />
                      <ListCell />
                      <ListCell />
                    </ListFields>

                    {
                      /** connexion to smartlink accounts */
                      currentTab === TAB_SELF && (
                        <div>
                          <ListItem isEditable onClick={() => getAccountDemo(false)}>
                            <ListIcon
                              fallbackIcon={faShieldAlt}
                              iconColor={ACCOUNT_DEMO_COLOR}
                              image={env.REACT_APP_URL_SPACE.concat("/Accounts/", ACCOUNT_DEMO.id, ".png")}
                            />

                            <ListCell width={200} text={ACCOUNT_DEMO.name} />

                            <Chip color={ACCOUNT_DEMO_COLOR}>{t("account_demo")}</Chip>

                            <Space />

                            <ListButton
                              icon={faPlayCircle}
                              onClick={() => getAccountDemo(false)}
                              isPrimary
                              text={t("utils_connect")}
                            />
                          </ListItem>

                          <ListItem isEditable onClick={() => getAccountDemo(true)}>
                            <ListIcon
                              fallbackIcon={faShieldAlt}
                              iconColor={ACCOUNT_DEMO_COLOR}
                              image={env.REACT_APP_URL_SPACE.concat("/Accounts/", ACCOUNT_DEMO_GROUP.id, ".png")}
                            />

                            <ListCell width={200} text={ACCOUNT_DEMO_GROUP.name} />

                            <Chip color={ACCOUNT_DEMO_COLOR}>{t("account_demo")} (ETI)</Chip>

                            <Space />

                            <ListButton
                              icon={faPlayCircle}
                              onClick={() => getAccountDemo(true)}
                              isPrimary
                              text={t("utils_connect")}
                            />
                          </ListItem>
                        </div>
                      )
                    }

                    {props.account.list.map((account: Account) => (
                      <ListItem isEditable onClick={() => getAccount(account)} key={account.id}>
                        <ListIcon
                          fallbackIcon={faShieldAlt}
                          iconColor={account.colors.brandPrimary}
                          image={
                            account.options.image
                              ? env.REACT_APP_URL_SPACE.concat("/Accounts/", account.id, ".png?" + Date.now())
                              : null
                          }
                        />

                        <ListCell width={200} text={account.name} />

                        <div style={{ width: 260 }}>
                          <div className="flex">
                            {account.Projects[0] ? (
                              <Chip color={account.Projects[0].statusColor}>{account.projectStatus}</Chip>
                            ) : (
                              account.projectStatus
                            )}
                          </div>

                          {account.options.onboardingAction && (
                            <div
                              className="grey-t"
                              style={{
                                fontSize: 12,
                                marginTop: account.projectStatus ? 8 : 0
                              }}>
                              Action : <b>{t("home_welcome_" + account.options.onboardingAction)}</b>
                            </div>
                          )}
                        </div>

                        <Space />

                        <ListCell width={120}>
                          {getAccountUnpaid(account.Subscriptions) > 0 && (
                            <Chip icon={faTag}>{getAccountUnpaid(account.Subscriptions) + "€"}</Chip>
                          )}
                        </ListCell>

                        <ListCell width={160}>{getAccountType(account)}</ListCell>

                        <ListCell width={180}>
                          <ModulesWidget modules={account.modules} readonly />
                        </ListCell>

                        <ListButton icon={faPencilAlt} onClick={() => editAccount(account)} text={t("account_edit")} />

                        <ListButton
                          icon={faPlayCircle}
                          onClick={() => getAccount(account)}
                          isPrimary
                          text={t("utils_connect")}
                        />
                      </ListItem>
                    ))}
                  </div>
                )
              }
            </PageLoader>
          </ListContainer>
        </Card>
      )}

      {props._session.supervisorLevel > 2 && <DatabaseHostWidget />}
    </NavSupervisor>
  )
}

const mapStateToProps = (state) => ({
  _session: state._session,
  account: state.account
})

export default connect(mapStateToProps)(withTranslation()(SupervisorAccounts))
