/**
 * TASKS-RELAUNCH.MODAL
 * Reactivate action plan
 */
import { store } from "@/index"
import { useState } from "react"
import { WithTranslation, withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Session } from "@/redux/_session.types"
import { ActionPlanState } from "@/redux/action-plan.types"
import { actionPlanEdit, actionPlanLaunch } from "@/redux/action-plan.actions"
import ModalConfirm from "@/components/modal-confirm"
import { STATUS_SAVED, STATUS_SAVING } from "@/redux/_status.types"

interface StateProps extends WithTranslation {
  _session: Session
  actionPlan: ActionPlanState
}

interface OwnProps {
  onClose: Function
}

type Props = StateProps & OwnProps

function TasksRelaunchModal(props: Props) {
  const { t } = props

  const [isSaving, setIsSaving] = useState(false)

  //Click on confirm
  async function relaunchConfirm() {
    setIsSaving(true)

    const response: any = await store.dispatch(
      actionPlanLaunch(props.actionPlan.active.id, false, props._session.userName)
    )

    if (!response.error) {
      store.dispatch(actionPlanEdit("status", "active", false))
      props.onClose()
    } else {
      setIsSaving(false)
    }
  }

  return (
    <ModalConfirm
      status={isSaving ? STATUS_SAVING : STATUS_SAVED}
      textBold={t("tasks_relaunch_ask")}
      onNo={() => props.onClose()}
      onYes={() => relaunchConfirm()}></ModalConfirm>
  )
}

const mapStateToProps = (state) => ({
  _session: state._session,
  actionPlan: state.actionPlan
})

export default connect(mapStateToProps)(withTranslation()(TasksRelaunchModal))
