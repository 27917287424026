/**
 * SUPERVISOR.ACCOUNT
 * Edit account options
 */
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { WithTranslation, withTranslation } from "react-i18next"
import { AccountModules, AccountState, ACCOUNT_LEVELS } from "@/redux/account.types"
import NavFocus from "@/nav/nav.focus"
import PageHeader from "@/components/page-header"
import Button from "@/components/button"
import Modal from "@/components/modal"
import TextInput from "@/components/text-input"
import { store } from "@/index"
import {
  accountEdit,
  accountStatus,
  accountUpdateModules_AsSupervisor,
  accountUpdate_AsSupervisor
} from "@/redux/account.actions"
import { STATUS_SAVED, STATUS_SAVE_ERROR, STATUS_SAVING } from "@/redux/_status.types"
import Checkbox from "@/components/checkbox"
import ListItem from "@/components/list-item"
import Card from "@/components/card"
import Space from "@/components/space"
import { subscriptionAdd, subscriptionRemove, subscriptionDestroy } from "@/redux/subscription.actions"
import { Subscription, SubscriptionState } from "@/redux/subscription.types"
import DeleteAccountModal from "@/modals/delete-account.modal"
import LoginModal from "@/modals/login.modal"
import { Session } from "@/redux/_session.types"
import { faSkull } from "@fortawesome/free-solid-svg-icons"
import ModulesWidget from "@/widgets/modules.widget"
import ModalConfirm from "@/components/modal-confirm"
import Dropdown from "@/components/dropdown"
import SubscriptionEditorWidget from "@/widgets/subscription-editor.widget"
import { NavigateFunction, useNavigate } from "react-router-dom"
import { supervisorFetch, supervisorGet } from "@/redux/supervisor.actions"
import Link from "@/components/link"
import ConvertToSuperaccountModal from "@/modals/convert-to-superaccount.modal"
import { Supervisor } from "@/redux/supervisor.types"
import { surveyCreateDemo_AsSupervisor } from "@/redux/survey.actions"
import EnterCodeWidget from "@/widgets/enter-code.widget"
import { toast } from "react-toastify"
import { BounceIn } from "@/utils/animations.utils"
import AccountEditCsWidget from "@/widgets/account-edit-cs.widget"

interface Props extends WithTranslation {
  _session: Session
  account: AccountState
  subscription: SubscriptionState
}

//Modal
const MODAL_CONVERT: string = "MODAL_CONVERT" //Convertir
const MODAL_LOGIN: string = "MODAL_LOGIN" //Loading before connect to account
const MODAL_DELETE_CONFIRM: string = "MODAL_DELETE_CONFIRM" //Confirm delete
const MODAL_SUPERACCOUNT: string = "MODAL_SUPERACCOUNT" //Warning when change superaccount
const MODAL_GENERATE_DEMO: string = "MODAL_GENERATE_DEMO" //Warning demo data generation

function SuperTrials(props: Props) {
  const { t } = props
  const navigate: NavigateFunction = useNavigate()

  const queryParams: URLSearchParams = new URLSearchParams(window.location.search)
  const accountId: string | null = queryParams.get("accountId")

  const [currentModal, setCurrentModal] = useState<string | null>(null)
  const [isDemoGenerating, setIsDemoGenerating] = useState<boolean>(false)
  const [supervisors, setSupervisors] = useState<Supervisor[]>([])

  //Load supervisors list
  //Set supervisor and supervisor sales
  useEffect(() => {
    async function loadSupervisors() {
      const response: any = await store.dispatch(supervisorFetch(""))
      if (response.error) {
        store.dispatch(supervisorGet(response.error))
      } else {
        setSupervisors(response.map((x) => new Supervisor(x)))
        const supervisor = response.find((x) => x.id === props.account.active.SupervisorId)
        const supervisorSales = response.find((x) => x.id === props.account.active.SupervisorIdSales)

        if (supervisor) {
          store.dispatch(accountEdit("Supervisor", new Supervisor(supervisor)))
        }

        if (supervisorSales) {
          store.dispatch(accountEdit("SupervisorSales", new Supervisor(supervisorSales)))
        }
      }
    }

    loadSupervisors()
  }, [])

  //Add subscription
  function addSubscription() {
    store.dispatch(subscriptionAdd(props.account.active.id))
  }

  //Delete account
  //Open confirmation modal
  function deleteAccount() {
    setCurrentModal(MODAL_DELETE_CONFIRM)
  }

  //Delete subscription
  async function deleteSubscription(subscriptionId: string) {
    store.dispatch(subscriptionRemove(subscriptionId))
    store.dispatch(accountStatus(STATUS_SAVING))
    const response: any = await store.dispatch(subscriptionDestroy(subscriptionId))
    store.dispatch(accountStatus(response.error ? STATUS_SAVE_ERROR : STATUS_SAVED))
  }

  async function generateSurveyDemo() {
    setIsDemoGenerating(true)
    setCurrentModal(null)
    toast(t("Génération lancée, veuillez patienter quelques minutes !"), { type: "success" })
    await store.dispatch(surveyCreateDemo_AsSupervisor(props.account.active.id))
  }

  //Connect to account
  function getAccount() {
    setCurrentModal(MODAL_LOGIN)
  }

  //Get supervisor
  function getSupervisor(isSales: boolean) {
    return isSales ? props.account.active.SupervisorSales : props.account.active.Supervisor
  }

  //Edit informations on account
  function handleAccountChanges(event: any) {
    store.dispatch(accountEdit(event.id, event.value))
  }

  //Change checkbox value
  function handleAccountCheckbox(event: any) {
    if (event.value === false && event.id === "isSuperAccount") {
      setCurrentModal(MODAL_SUPERACCOUNT)
    } else {
      handleAccountChanges(event)
      saveAccount()
    }
  }

  //Change level of the account
  function handleAccountLevel(value) {
    handleAccountChanges({ id: "level", value })
    saveAccount()
  }

  //Change options
  function handleAccountOptionsChanges(key, value) {
    const options = Object.assign({}, props.account.active.options, { [key]: value })
    handleAccountChanges({ id: "options", value: options })
  }

  //Remove superaccount property
  function removeSuperAccount() {
    setCurrentModal(null)
    handleAccountChanges({ id: "isSuperAccount", value: false })
    saveAccount()
  }

  //Save account
  async function saveAccount() {
    store.dispatch(accountStatus(STATUS_SAVING))
    const response: any = await store.dispatch(accountUpdate_AsSupervisor(props.account.active))
    store.dispatch(accountStatus(response.error ? STATUS_SAVE_ERROR : STATUS_SAVED))
  }

  //Select and save modules
  async function selectModules(modules: AccountModules) {
    store.dispatch(accountStatus(STATUS_SAVING))
    store.dispatch(accountEdit("modules", modules))

    const response: any = await store.dispatch(accountUpdateModules_AsSupervisor(props.account.active.id, modules))
    store.dispatch(accountStatus(response.error ? STATUS_SAVE_ERROR : STATUS_SAVED))
  }

  //Select supervisor and edit account
  function selectSupervisor(supervisor: Supervisor | null, isSales) {
    if (isSales) {
      store.dispatch(accountEdit("SupervisorSales", supervisor))
      store.dispatch(accountEdit("SupervisorIdSales", supervisor ? supervisor.id : null))
    } else {
      store.dispatch(accountEdit("Supervisor", supervisor))
      store.dispatch(accountEdit("SupervisorId", supervisor ? supervisor.id : null))
    }
  }

  return (
    <NavFocus text={t("accounts")} onBack={() => navigate("/supervisor/accounts" + (accountId ? "?tab=TAB_SUBS" : ""))}>
      {currentModal === MODAL_CONVERT && <ConvertToSuperaccountModal onClose={() => setCurrentModal(null)} />}

      {
        /** dialog to connect to login page */
        currentModal === MODAL_LOGIN && (
          <LoginModal
            authLevel={2}
            isSupervisor
            accountIdForSuperadmin={props.account.active.id}
            email={props._session.email}
          />
        )
      }

      {
        /** warning when change superaccount */
        currentModal === MODAL_SUPERACCOUNT && (
          <ModalConfirm
            onNo={() => setCurrentModal(null)}
            onYes={removeSuperAccount}
            text={t("account_remove_superaccount")}
            textBold={t("utils_next_ask")}
          />
        )
      }

      {
        /** dialog to delete account */
        currentModal === MODAL_DELETE_CONFIRM && (
          <DeleteAccountModal
            onClose={() => setCurrentModal(null)}
            accountId={props.account.active.id}
            accountName={props.account.active.name}
          />
        )
      }

      {currentModal === MODAL_GENERATE_DEMO && (
        <Modal onClose={() => setCurrentModal(null)} title={t("generate")}>
          <EnterCodeWidget
            onCancel={() => setCurrentModal(null)}
            labelCta={t("utils_next")}
            onNext={
              isDemoGenerating
                ? () => toast(t("Génération déjà lancée, merci de patienter quelques minutes !"), { type: "error" })
                : generateSurveyDemo
            }
            status={props.account.status}
          />
        </Modal>
      )}

      <PageHeader title={t("account_edit")}>
        <Button onClick={deleteAccount}>{t("account_delete")}</Button>

        <Button className="primary" onClick={getAccount}>
          {t("utils_connect")}
        </Button>
      </PageHeader>

      <h3>{t("account_informations")}</h3>

      <div className="flex">
        <TextInput
          id="name"
          onBlur={saveAccount}
          onChange={handleAccountChanges}
          status={props.account.status}
          title={t("account_name")}
          value={props.account.active.name}
        />
      </div>

      <div className="flex">
        <Dropdown
          active={props.account.active.level}
          displayField="name"
          list={ACCOUNT_LEVELS.map((x) => {
            return { id: x, name: t("account_app_level_" + x) }
          })}
          title={t("account_app_level")}
          onSelect={(e) => handleAccountLevel(e.id)}
          status={props.account.status}
          value={props.account.active.level ? t("account_app_level_" + props.account.active.level) : null}
        />

        {props._session.supervisorLevel > 1 && (
          <Dropdown
            active={getSupervisor(true)?.id}
            displayField="name"
            list={supervisors}
            isNullAllowed
            title={t("supervisor_sales")}
            onSave={saveAccount}
            onSelect={(supervisor) => selectSupervisor(supervisor, true)}
            status={props.account.status}
            value={getSupervisor(true)?.name}
          />
        )}

        {props._session.supervisorLevel > 1 && (
          <Dropdown
            active={getSupervisor(false)?.id}
            displayField="name"
            list={supervisors}
            isNullAllowed
            title={t("supervisor")}
            onSave={saveAccount}
            onSelect={(supervisor) => selectSupervisor(supervisor, false)}
            status={props.account.status}
            value={getSupervisor(false)?.name}
          />
        )}
      </div>

      <div
        className="flex"
        style={{
          marginTop: 20,
          marginBottom: 20
        }}>
        <Checkbox
          active={props.account.active.isSuperAccount}
          id="isSuperAccount"
          status={props.account.status}
          text={t("account_consulting")}
          onClick={handleAccountCheckbox}
        />

        {!props.account.active.isSuperAccount && (
          <div className="flex-auto" onClick={() => setCurrentModal(MODAL_CONVERT)}>
            <Link isWithoutMargin>{t("account_convert")}</Link>
          </div>
        )}

        <div className="flex1" />

        <Checkbox
          active={props.account.active.isSelfService}
          id="isSelfService"
          status={props.account.status}
          text={t("account_self_service")}
          onClick={handleAccountCheckbox}
        />

        <Space />
      </div>

      <div className="text-input-title grey-t" style={{ marginBottom: 12 }}>
        Resell
      </div>

      <div className="flex" style={{ marginBottom: 32 }}>
        <AccountEditCsWidget currentAccount={props.account.active} />
      </div>

      <ModulesWidget
        modules={props.account.active.modules}
        status={props.account.status}
        onChange={(modules: AccountModules) => selectModules(modules)}
      />

      <div className="height-20" />

      <div className="flex">
        <TextInput
          id="invitations"
          type="number"
          onBlur={saveAccount}
          onChange={handleAccountChanges}
          status={props.account.status}
          title={t("account_invitations")}
          value={props.account.active.invitations}
        />

        <TextInput
          id="participationMin"
          type="number"
          onBlur={saveAccount}
          onChange={handleAccountChanges}
          status={props.account.status}
          title={t("account_participation_min")}
          value={props.account.active.participationMin}
        />

        <TextInput
          id="ceilsTesters"
          type="number"
          onBlur={saveAccount}
          onChange={(e) => handleAccountOptionsChanges(e.id, parseInt(e.value))}
          status={props.account.status}
          title={t("account_participation_test")}
          value={props.account.active.options.ceilsTesters}
        />

        <Space />
      </div>

      <div className="height-20" />

      <ListItem>
        <div
          className="flex1 red-t"
          style={{
            fontWeight: "bold",
            textAlign: "right",
            margin: "auto",
            marginRight: 20,
            fontSize: 24
          }}>
          {t("generate_survey_demo")}
        </div>

        <BounceIn>
          <Button
            icon={faSkull}
            isLarge
            isLoading={isDemoGenerating}
            className="warning"
            onClick={() =>
              isDemoGenerating
                ? toast(t("Génération déjà lancée, merci de patienter quelques minutes !"), { type: "error" })
                : setCurrentModal(MODAL_GENERATE_DEMO)
            }>
            {t("GO")}
          </Button>
        </BounceIn>
      </ListItem>

      <hr></hr>

      <div className="flex">
        <h3 id="subscriptions_section">{t("subscriptions")}</h3>
        <Space />
        <Button onClick={addSubscription} className="secondary">
          {t("subscription_add")}
        </Button>
      </div>

      {props.subscription.list.length === 0 && <p className="grey-t">{t("account_no_active_subscription")}</p>}

      <div className="height-20" />

      {props.subscription.list.map((subscription: Subscription) => (
        <Card key={subscription.id}>
          <SubscriptionEditorWidget subscription={subscription} />

          <div className="height-20" />

          <div className="flex">
            <Space />
            <Button onClick={() => deleteSubscription(subscription.id)}>{t("subscription_delete")}</Button>
          </div>
        </Card>
      ))}

      <div className="height-200" />
    </NavFocus>
  )
}

const mapStateToProps = (state) => ({
  _session: state._session,
  account: state.account,
  subscription: state.subscription
})

export default connect(mapStateToProps)(withTranslation()(SuperTrials))
