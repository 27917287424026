/**
 * TASKS.HOME
 * Home page for actions plans
 */
import PageHeader from "@/components/page-header"
import NavApp from "@/nav/nav.app"
import { WithTranslation, withTranslation } from "react-i18next"
import { useState } from "react"
import { connect } from "react-redux"
import { Session } from "@/redux/_session.types"
import TasksActionPlansWidget from "@/widgets/tasks-action-plans.widget"
import Button from "@/components/button"
import TasksAddModal from "@/modals/tasks-add.modal"

interface StateProps extends WithTranslation {
  _session: Session
}

const MODAL_ADD = "MODAL_ADD"

function TasksHomeRoute(props: StateProps) {
  const { t } = props
  const [currentModal, setCurrentModal] = useState<string | null>(null)
  return (
    <NavApp>
      {currentModal === MODAL_ADD && <TasksAddModal onClose={() => setCurrentModal(null)} />}
      <PageHeader title={t("tasks")} subtitle={t("tasks_help")}>
        <Button className="primary" onClick={() => setCurrentModal(MODAL_ADD)}>
          {t("tasks_add")}
        </Button>
      </PageHeader>
      <TasksActionPlansWidget isSelf title={props._session.userRole === "ADMIN" ? t("tasks_self") : undefined} />
      {props._session.userRole === "ADMIN" && <TasksActionPlansWidget isPredraft title={t("tasks_predraft")} />}
      {props._session.userRole === "ADMIN" && <TasksActionPlansWidget title={t("tasks_all")} />}
    </NavApp>
  )
}

const mapStateToProps = (state) => ({
  _session: state._session
})

export default connect(mapStateToProps)(withTranslation()(TasksHomeRoute))
