/**
 * TASKS-LAUNCH.MODAL
 * Edit options for the action plan
 */
import { faPaperPlane, faTimes } from "@fortawesome/free-solid-svg-icons"
import Button from "@/components/button"
import CardButton from "@/components/card-button"
import Modal from "@/components/modal"
import Space from "@/components/space"
import { store } from "@/index"
import { useState } from "react"
import { WithTranslation, withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Session } from "@/redux/_session.types"
import { ActionPlanState } from "@/redux/action-plan.types"
import { UserState } from "@/redux/user.types"
import TasksOptionsWidget from "@/widgets/tasks-options.widget"
import LoadingModal from "./loading.modal"
import { actionPlanEdit, actionPlanLaunch } from "@/redux/action-plan.actions"
import CongratulationModal from "./congratulation.modal"
import { useNavigate } from "react-router-dom"

interface StateProps extends WithTranslation {
  _session: Session
  actionPlan: ActionPlanState
  user: UserState
}

interface OwnProps {
  onClose: Function
}

type Props = StateProps & OwnProps

const MODAL_DONE = "MODAL_DONE"
const MODAL_LOADING = "MODAL_LOADING"
const MODAL_LAUNCH_CONFIRM = "MODAL_LAUNCH_CONFIRM"

function TasksLaunchModal(props: Props) {
  const { t } = props
  const navigate = useNavigate()

  const [currentModal, setCurrentModal] = useState<string | null>(null)

  //Option if an email should be send to the user
  const [sendEmail, setSendEmail] = useState(false)

  //Click on confirm
  async function launchConfirm() {
    setCurrentModal(MODAL_LOADING)

    const response: any = await store.dispatch(
      actionPlanLaunch(props.actionPlan.active.id, sendEmail, props._session.userName)
    )

    if (!response.error) {
      store.dispatch(actionPlanEdit("status", "active", false))
      navigate("/tasks/home")
      setCurrentModal(MODAL_DONE)
    } else {
      setCurrentModal(MODAL_LAUNCH_CONFIRM)
    }
  }

  function isEmailProvided(): boolean {
    if (props.user.active.email && props.user.active.email.length > 0) {
      return true
    } else {
      return false
    }
  }

  return currentModal === MODAL_DONE ? (
    <CongratulationModal text={t("tasks_congratulations")} onClose={() => props.onClose()} />
  ) : (
    <Modal
      isCloseButtonVisible
      isLarge
      title={t("tasks_launch")}
      onClose={() => props.onClose()}
      onNext={() =>
        props._session.userId === props.actionPlan.active.UserId
          ? launchConfirm()
          : setCurrentModal(MODAL_LAUNCH_CONFIRM)
      }>
      {currentModal === MODAL_LOADING && <LoadingModal />}

      {currentModal === MODAL_LAUNCH_CONFIRM && (
        <Modal onClose={() => setCurrentModal(null)}>
          <div
            className="flex flex-dcol"
            style={{
              width: 400,
              margin: "auto",
              textAlign: "center"
            }}>
            <p>
              <b>
                {t("tasks_launch_text_1", {
                  name: props.user.active.username,
                  email: props.user.active.email
                })}
              </b>
            </p>
            <p className="grey-t" style={{ fontSize: 12 }}>
              {t("tasks_launch_text_2")}
            </p>
          </div>
          <div className="height-10" />
          <div className="flex">
            <Space />
            <CardButton
              title={
                isEmailProvided()
                  ? t("tasks_launch_email_yes", { email: props.user.active.email })
                  : t("invitation_no_email")
              }
              isActive={sendEmail}
              isLock={!isEmailProvided()}
              onClick={() => (isEmailProvided() ? setSendEmail(true) : {})}
              icon={faPaperPlane}
            />
            <CardButton
              title={t("tasks_launch_email_no")}
              isActive={!sendEmail}
              onClick={() => setSendEmail(false)}
              icon={faTimes}
            />
            <Space />
          </div>
          <div className="height-20" />
          <div className="flex">
            <Space />
            <Button onClick={launchConfirm} isLarge isFullWidth className="primary">
              {t("tasks_launch")}
            </Button>
            <Space />
          </div>
          <div className="flex">
            <Space />
            <Button isFullWidth onClick={() => setCurrentModal(null)}>
              {t("tasks_launch_cancel")}
            </Button>
            <Space />
          </div>
        </Modal>
      )}

      <TasksOptionsWidget />
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  _session: state._session,
  actionPlan: state.actionPlan,
  user: state.user
})

export default connect(mapStateToProps)(withTranslation()(TasksLaunchModal))
