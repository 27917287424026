/**
 * TASKS-EDIT.WIDGET
 * Edit action plan
 */

import { connect } from "react-redux"
import { ActionPlanState } from "@/redux/action-plan.types"
import TasksListWidget from "./tasks-list.widget"
import TasksTopicsWidget from "./tasks-topics.widget"
import { useEffect, useState } from "react"
import { STATUS_LOADED, STATUS_LOADING, STATUS_SAVING } from "@/redux/_status.types"
import { store } from "@/index"
import { actionPlanUpdateAndStatus } from "@/redux/action-plan.actions"
import { TopicState } from "@/redux/topic.types"
import AdmUserPopulationsWidget from "./adm-user-populations.widget"
import PageHeader from "@/components/page-header"
import { WithTranslation, withTranslation } from "react-i18next"
import { loadSurveyAndTopics } from "@/redux/_archive.actions"
import { Session } from "@/redux/_session.types"
import { surveyActivate, surveyFetch, surveyGet } from "@/redux/survey.actions"
import { Survey } from "@/redux/survey.types"
import { topicGet } from "@/redux/topic.actions"
import PageLoader from "@/components/page-loader"

interface StateProps extends WithTranslation {
  _session: Session
  actionPlan: ActionPlanState
  topic: TopicState
}

function TasksEditWidget(props: StateProps) {
  const { t } = props

  //Set is loading
  const [isLoading, setIsLoading] = useState(true)

  //Detect that topics are loaded
  useEffect(() => {
    if (props.topic.list.length > 0) {
      setIsLoading(false)
    }
  }, [props.topic.list.length])

  //Load survey
  useEffect(() => {
    loadSurveys()

    async function loadSurveys() {
      const response: any = await store.dispatch(surveyFetch())

      if (response.error) {
        store.dispatch(surveyGet([], [], true))
        store.dispatch(surveyActivate(new Survey()))
        store.dispatch(topicGet([]))
      } else {
        store.dispatch(surveyGet(response, [], false))

        //Detect if a survey is defined
        //If not search the last one
        const actionPlanSurvey: Survey | undefined = response.find(
          (x: Survey) => x.id === props.actionPlan.active.referenceSurveyId
        )

        if (actionPlanSurvey) {
          //Load topics
          store.dispatch(surveyActivate(actionPlanSurvey))
          store.dispatch(loadSurveyAndTopics(actionPlanSurvey.id))
        } else {
          setIsLoading(false)
          store.dispatch(surveyActivate(new Survey()))
          store.dispatch(topicGet([]))
        }
      }
    }
  }, [props.actionPlan.active.referenceSurveyId])

  //Detect changes in order to save
  useEffect(() => {
    if (props.actionPlan.status === STATUS_SAVING) {
      update()
    }

    async function update() {
      store.dispatch(actionPlanUpdateAndStatus())
    }
  }, [props.actionPlan.status])

  return (
    <PageLoader status={isLoading ? STATUS_LOADING : STATUS_LOADED}>
      <PageHeader title={t("tasks_edit")} />

      <div
        style={{
          marginTop: -32,
          marginBottom: 28
        }}>
        <AdmUserPopulationsWidget currentUser={props.actionPlan.active.User} />
      </div>

      <div className="flex">
        {(props.actionPlan.active.status === "draft" || props.actionPlan.active.status === "predraft") && (
          <TasksTopicsWidget />
        )}
        <TasksListWidget />
      </div>
    </PageLoader>
  )
}

const mapStateToProps = (state) => ({
  _session: state._session,
  actionPlan: state.actionPlan,
  topic: state.topic
})

export default connect(mapStateToProps)(withTranslation()(TasksEditWidget))
