/**
 * TASKS-OPTIONS.WIDGET
 * Change options for an action plan
 */
import TextInput from "@/components/text-input"
import { store } from "@/index"
import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { actionPlanEdit, actionPlanUpdate } from "@/redux/action-plan.actions"
import { ActionPlanState } from "@/redux/action-plan.types"
import { formatDate } from "@/utils/format-date.utils"
import Button from "@/components/button"
import ListButton from "@/components/list-button"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { snakeCase } from "lodash"
import { Session } from "@/redux/_session.types"
import Checkbox from "@/components/checkbox"

interface StateProps extends WithTranslation {
  _session: Session
  actionPlan: ActionPlanState
}

function TasksOptionsWidget(props: StateProps) {
  const { t } = props

  return (
    <div>
      <div className="flex">
        <TextInput
          onChange={(e) => store.dispatch(actionPlanEdit("priority", e.value, false))}
          title={t("tasks_priority")}
          value={props.actionPlan.active.priority}
          onBlur={() => store.dispatch(actionPlanUpdate(props.actionPlan.active))}
        />

        <div className="width-40" />

        {props.actionPlan.active.UserId === props._session.userId && (
          <Checkbox
            active={props.actionPlan.active.isShared}
            text={t("tasks_is_shared")}
            onClick={() => store.dispatch(actionPlanEdit("isShared", !props.actionPlan.active.isShared, true))}
          />
        )}
      </div>

      <TextInput
        onChange={(e) => store.dispatch(actionPlanEdit("expectedOutcome", e.value, false))}
        title={t("tasks_expected_outcome")}
        isFullWidth
        value={props.actionPlan.active.expectedOutcome}
        onBlur={() => store.dispatch(actionPlanUpdate(props.actionPlan.active))}
      />

      <div className="flex" style={{ height: 110 }}>
        {["dateStart", "dateEnd"].map((item) =>
          props.actionPlan.active[item] ? (
            <div className="flex" key={item}>
              <TextInput
                type="date"
                isFullWidth
                onChange={(e) => store.dispatch(actionPlanEdit(item, new Date(e.value), true))}
                title={t("tasks_" + snakeCase(item))}
                value={formatDate(props.actionPlan.active[item], false, true)}
              />
              <div style={{ marginTop: 54 }}>
                <ListButton icon={faTimes} onClick={() => store.dispatch(actionPlanEdit(item, null, true))} />
              </div>
            </div>
          ) : (
            <div
              key={item}
              style={{
                marginRight: 20,
                marginTop: 36
              }}>
              <Button
                onClick={() =>
                  store.dispatch(
                    actionPlanEdit(item, item === "dateStart" ? new Date() : new Date(Date.now() + 5184000000), true) //+ 60 days
                  )
                }
                isFullWidth
                isWithBorder>
                {t("tasks_" + snakeCase(item) + "_cta")}
              </Button>
            </div>
          )
        )}
      </div>

      <div className="height-40" />
    </div>
  )
}

const mapStateToProps = (state) => ({
  _session: state._session,
  actionPlan: state.actionPlan
})

export default connect(mapStateToProps)(withTranslation()(TasksOptionsWidget))
