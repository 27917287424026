/**
 * ACTION-PLAN.ACTIONS
 * Actions related to the actions plans
 */

import {
  ActionPlan,
  ACTION_PLAN_ACTIVATE,
  ACTION_PLAN_EDIT,
  ACTION_PLAN_STATUS,
  ACTION_PLAN_ADD,
  ACTION_PLAN_ADD_TASK,
  ACTION_PLAN_REMOVE_TASK,
  ACTION_PLAN_INIT,
  ACTION_PLAN_EDIT_TASK,
  ActionPlanTask,
  ACTION_PLAN_ADD_TASK_MESSAGE,
  ACTION_PLAN_REMOVE_TASK_MESSAGE,
  ActionPlanStatus
} from "./action-plan.types"
import { STATUS_SAVED } from "./_status.types"
import { User } from "./user.types"

//STORE

export const actionPlanActivate = (actionPlan: ActionPlan) => ({
  type: ACTION_PLAN_ACTIVATE,
  payload: {
    actionPlan
  }
})

export const actionPlanAdd = (
  user: User,
  referenceSurveyId: string | null,
  priority: string,
  status: ActionPlanStatus,
  isShared: boolean
) => ({
  type: ACTION_PLAN_ADD,
  payload: {
    user,
    referenceSurveyId,
    priority,
    status,
    isShared
  }
})

export const actionPlanAddTask = (topicAid: string | null, title: string, text: string, createdBy: string) => ({
  type: ACTION_PLAN_ADD_TASK,
  payload: {
    topicAid,
    title,
    text,
    createdBy
  }
})

export const actionPlanAddTaskMessage = (taskId: string, text: string, username: string, userId: string) => ({
  type: ACTION_PLAN_ADD_TASK_MESSAGE,
  payload: {
    taskId,
    text,
    username,
    userId
  }
})

export const actionPlanEdit = (key: string, value: any, saveChanges: boolean) => ({
  type: ACTION_PLAN_EDIT,
  payload: {
    key,
    value,
    saveChanges
  }
})

export const actionPlanEditTask = (task: ActionPlanTask) => ({
  type: ACTION_PLAN_EDIT_TASK,
  payload: {
    task
  }
})

export const actionPlanInit = () => ({
  type: ACTION_PLAN_INIT
})

export const actionPlanRemoveTask = (taskId: string) => ({
  type: ACTION_PLAN_REMOVE_TASK,
  payload: {
    taskId
  }
})

export const actionPlanRemoveTaskMessage = (taskId: string, messageId: string) => ({
  type: ACTION_PLAN_REMOVE_TASK_MESSAGE,
  payload: {
    taskId,
    messageId
  }
})

export const actionPlanStatus = (status: string) => ({
  type: ACTION_PLAN_STATUS,
  payload: {
    status
  }
})

//API

export const actionPlanArchive = (actionPlanId: string) => ({
  type: "API",
  payload: {
    method: "POST",
    url: "/action-plan/archive",
    data: {
      actionPlanId
    }
  }
})

export const actionPlanClose = (actionPlanId: string) => ({
  type: "API",
  payload: {
    method: "POST",
    url: "/action-plan/close",
    data: {
      actionPlanId
    }
  }
})

export const actionPlanFetch = (status: string) => ({
  type: "API",
  payload: {
    method: "GET",
    url: "/action-plans",
    data: {
      status
    }
  }
})

export const actionPlanFetchSelf = () => ({
  type: "API",
  payload: {
    method: "GET",
    url: "/action-plans/self"
  }
})

export const actionPlanFetchTasks = (topicAid: string) => ({
  type: "API",
  payload: {
    method: "GET",
    url: "/action-plans/tasks",
    data: {
      topicAid
    }
  }
})

export const actionPlanLaunch = (actionPlanId: string, sendEmail: boolean, sender: string) => ({
  type: "API",
  payload: {
    method: "POST",
    url: "/action-plan/launch",
    data: {
      actionPlanId,
      sendEmail,
      sender
    }
  }
})

export const actionPlanSend = (actionPlanId: string, sender: string) => ({
  type: "API",
  payload: {
    method: "POST",
    url: "/action-plan/send",
    data: {
      actionPlanId,
      sender
    }
  }
})

export const actionPlanSendMessage = (
  actionPlanId: string,
  taskText: string,
  message: string,
  sender: string,
  isSendToAdmin: boolean
) => ({
  type: "API",
  payload: {
    method: "POST",
    url: "/action-plan/send-message",
    data: {
      actionPlanId,
      taskText,
      message,
      sender,
      isSendToAdmin
    }
  }
})

export const actionPlanUpdate = (actionPlan: ActionPlan) => ({
  type: "API",
  payload: {
    method: "POST",
    url: "/action-plan",
    data: {
      actionPlan
    }
  }
})

export const actionPlanDestroy = (actionPlanId: string) => ({
  type: "API",
  payload: {
    method: "DELETE",
    url: "/action-plan/" + actionPlanId
  }
})

// THUNK

export const actionPlanUpdateAndStatus: any = () => async (dispatch, getState) => {
  await dispatch(actionPlanUpdate(getState().actionPlan.active))
  dispatch(actionPlanStatus(STATUS_SAVED))
}
