/**
 * TASKS.EDIT
 * Edit an action plan
 */
import NavFocus from "@/nav/nav.focus"
import { connect } from "react-redux"
import { NavigateFunction, useNavigate } from "react-router-dom"
import { ActionPlanState } from "@/redux/action-plan.types"
import TasksEditWidget from "@/widgets/tasks-edit.widget"
import TasksActionsWidget from "@/widgets/tasks-actions.widget"

interface StateProps {
  actionPlan: ActionPlanState
}

function TasksEditRoute(props: StateProps) {
  const navigate: NavigateFunction = useNavigate()
  return (
    <NavFocus
      onBack={() => navigate("/tasks/home")}
      status={props.actionPlan.status}
      customActions={<TasksActionsWidget />}>
      <TasksEditWidget />
    </NavFocus>
  )
}

const mapStateToProps = (state) => ({
  actionPlan: state.actionPlan
})

export default connect(mapStateToProps)(TasksEditRoute)
