/**
 * GET-SURVEY_RESULTS.MODAL
 * If there is a survey draft or launch active, load attributes and go to page
 */

import { connect } from "react-redux"
import { useEffect } from "react"
import { Survey, SurveyState } from "@/redux/survey.types"
import LoadingModal from "./loading.modal"
import { store } from "@/index"
import { surveyActivate, surveyFetchOne } from "@/redux/survey.actions"
import { sortBy } from "lodash"
import { templateFetchOne } from "@/redux/template.actions"
import { Session } from "@/redux/_session.types"
import { filterGet, filterReplaceDashboard, filterReplaceDashboardCompare } from "@/redux/filter.actions"
import { topicFetchPublic, topicGet } from "@/redux/topic.actions"
import { axisGet } from "@/redux/axis.actions"
import { Axis } from "@/redux/axis.types"
import { Topic } from "@/redux/topic.types"
import { Question } from "@/redux/question.types"
import { bookmarkActivate, bookmarkFetch, bookmarkGet } from "@/redux/bookmark.actions"
import fetchArchiveFilters from "@/utils/fetch-archive-filters.utils"
import getAxisFromTopic from "@/utils/get-axis-from-topics.utils"
import { DashboardFilters, FilterState } from "@/redux/filter.types"
import fetchArchiveAttributes from "@/utils/fetch-archive-attributes.utils"
import { Attribute } from "@/redux/attribute.types"
import { attributeCompareGet, attributeGet } from "@/redux/attribute.actions"
import { regroupingFetch, regroupingGet } from "@/redux/regrouping.actions"
import { Bookmark } from "@/redux/bookmark.types"
import { Config } from "@/redux/_config.types"
import { toast } from "react-toastify"
import { WithTranslation, withTranslation } from "react-i18next"
import { ProjectAxisRule } from "@/redux/project.types"
import { NavigateFunction, useNavigate } from "react-router-dom"
import { actionPlanActivate, actionPlanAdd, actionPlanFetchSelf } from "@/redux/action-plan.actions"
import { User } from "@/redux/user.types"

interface StateProps {
  _session: Session
  filter: FilterState
  survey: SurveyState
}

interface OwnProps {
  destination?: string
  isDemo?: boolean
  onClose: Function
}

type Props = StateProps & OwnProps & WithTranslation

function GetSurveyResultsModal(props: Props) {
  const { t } = props
  const navigate: NavigateFunction = useNavigate()

  //On load
  //Detect if survey active is launched or closed
  //Else detect if there is other surveys
  useEffect(() => {
    async function goToResults() {
      let topics: any = []
      let segmentationByAxesRules: ProjectAxisRule[] = []

      if (props.isDemo) {
        const templateTest: any = await store.dispatch(templateFetchOne(new Config().demoTemplateId))
        topics = await store.dispatch(topicFetchPublic(templateTest?.id))

        //Liste des topics
        templateTest.Topics = topics.error ? [] : topics.error

        //Create test survey
        const survey = new Survey({
          name: templateTest.name[props._session.language],
          dateEnd: new Date(),
          randomData: true,
          template: templateTest,
          isTest: true
        })

        //Update topics => set primaryId
        topics.forEach((topic: Topic) => {
          topic.Questions.forEach((question: Question) => {
            if (question.id !== topic.questionPrimaryId) {
              question.primaryId = topic.questionPrimaryId
            }
          })
        })

        //Activate survey
        store.dispatch(surveyActivate(survey))
        store.dispatch(bookmarkGet([]))
      }
      //Else load data from the survey
      else if (props.survey.active.id.length > 0) {
        //Get survey from props
        const survey = new Survey(props.survey.active)
        const archiveSurvey = new Survey(survey)
        let appSurvey = new Survey()

        //Fetch data
        const response: any = await store.dispatch(surveyFetchOne(survey.id, false))

        if (!response.error) {
          //Build survey object
          appSurvey = new Survey(response)
          topics = appSurvey.template.Topics
          appSurvey.template.Topics = []
          appSurvey.participationDone = archiveSurvey.participationDone
          appSurvey.participationSend = archiveSurvey.participationSend
          appSurvey.participationStarted = archiveSurvey.participationStarted
          appSurvey.participationUndone = archiveSurvey.participationUndone
          segmentationByAxesRules = appSurvey.segmentationByAxesRules

          //Activate survey
          store.dispatch(surveyActivate(appSurvey))

          //Fetch filters
          const filters = await fetchArchiveFilters(new DashboardFilters(), appSurvey.id)

          //Update filters
          store.dispatch(filterGet(filters))

          //Fetch attributes
          const attributes: Attribute[] = await fetchArchiveAttributes(new DashboardFilters(), appSurvey.id, null)

          //Update attribute
          store.dispatch(attributeGet(attributes))
          store.dispatch(attributeCompareGet(attributes))

          //Fetch regroupings
          const regroupings: any = await store.dispatch(regroupingFetch(filters.map((x) => x.name)))
          if (regroupings.length > 0) {
            store.dispatch(regroupingGet(regroupings))
          } else {
            store.dispatch(regroupingGet([]))
          }

          //Fetch my action plans
          const actionPlans: any = await store.dispatch(actionPlanFetchSelf())

          //Filter by active survey
          const actionPlansSurvey = actionPlans.error
            ? []
            : actionPlans.filter((x) => x.referenceSurveyId === survey.id && x.status === "draft")

          //If no action plan found create one
          if (actionPlansSurvey.length === 0) {
            store.dispatch(
              actionPlanAdd(
                new User({ id: props._session.userId }),
                survey.id,
                t("tasks_name_survey", { name: survey.name }),
                "draft",
                false
              )
            )
          } else {
            store.dispatch(actionPlanActivate(actionPlansSurvey[0]))
          }

          //Fetch bookmarks
          //Order by defined from survey
          let bookmarks: any = await store.dispatch(bookmarkFetch(survey.id, props._session.userId))
          if (bookmarks.error) {
            store.dispatch(bookmarkGet([]))
          } else {
            bookmarks = sortBy(bookmarks, [
              (bookmark) => {
                return appSurvey.bookmarksOrder.indexOf(bookmark.id)
              }
            ])

            store.dispatch(bookmarkGet(bookmarks))
          }
        } else {
          store.dispatch(bookmarkGet([]))
          store.dispatch(filterGet([]))
        }
      } else {
        toast(t("no_survey_active"), { type: "error" })
      }

      //Store topics
      store.dispatch(filterReplaceDashboard(new DashboardFilters()))
      store.dispatch(filterReplaceDashboardCompare(new DashboardFilters()))
      store.dispatch(topicGet(topics))

      //Store axes
      let axes: Axis[] = getAxisFromTopic(topics)

      //Filter axes if they are filtered
      if (segmentationByAxesRules.length) {
        axes = axes.filter((x) => x.isDisplayed(segmentationByAxesRules, props.filter.observerAttributes))
      }

      store.dispatch(axisGet(axes, []))

      //Reset bookmark
      store.dispatch(bookmarkActivate(new Bookmark()))

      //Change page
      if (props.destination) {
        navigate(props.destination)
      }

      props.onClose()
    }

    goToResults()

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <LoadingModal />
}

const mapStateToProps = (state) => ({
  _session: state._session,
  filter: state.filter,
  survey: state.survey
})

export default connect(mapStateToProps)(withTranslation()(GetSurveyResultsModal))
