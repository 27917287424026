/**
 * ADM-USER-POPULATIONS.WIDGET
 * Get list of populations for one user
 */

import { User } from "@/redux/user.types"
import { connect } from "react-redux"
import { faCaretRight } from "@fortawesome/free-solid-svg-icons"
import { Session } from "@/redux/_session.types"
import { sortBy } from "lodash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface StateProps {
  _session: Session
}

interface OwnProps {
  currentUser: User
}

type Props = StateProps & OwnProps

function AdmUserPopulationsWidget(props: Props) {
  return (
    <div className="flex1">
      {(props.currentUser.role === "ADMIN" || props.currentUser.populationsObserver.length === 0) && (
        <div className="grey-t">
          <FontAwesomeIcon icon={faCaretRight} />
          &nbsp;
          {props._session.accountName}
        </div>
      )}

      {sortBy(props.currentUser.populationsObserver, "name").map((population, i) => (
        <div key={i} className="grey-t">
          <FontAwesomeIcon icon={faCaretRight} />
          &nbsp;
          {population.filterName} : <b>{population.name}</b>
        </div>
      ))}
    </div>
  )
}

const mapStateToProps = (state) => ({
  _session: state._session
})

export default connect(mapStateToProps)(AdmUserPopulationsWidget)
